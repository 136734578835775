export default {
  transaction_id: "Transaktions ID",
  service_date: "Datum der Dienstleistung",
  change_plan_button_title: "Plan ändern",
  trial_period: "Probezeit",
  minimum_value_for_payment_error: "Der Mindestwert ist 2.",
  choose_tenant_dialog_title:
    "Wählen Sie das Unternehmen, bei dem Sie sich anmelden möchten",
  select: "Auswählen",
  choose_tenant_dialog_forgotten_password_title:
    "Wählen Sie das Unternehmen, bei dem Sie das Passwort ändern möchten",
  user_uses_email_already:
    "Die ausgewählte E-Mail-Adresse wird vom Kunden oder einem anderen Mitarbeiter verwendet.",
  fields_required: "* - Pflichtfelder",
  add_resources_with_plus:
    'Keine Ressourcen. Fügen Sie Ressourcen hinzu, indem Sie auf der rechten Seite auf "+" klicken.',
  add_categories_with_plus:
    'Keine Kategorie verfügbar. Fügen Sie eine Kategorie hinzu, indem Sie auf der rechten Seite auf "+" klicken.',
  email_sent: "E-mail wurde gesendet",
  sms_sent: "SMS-Nachricht wurde gesendet",
  payment_method_missing:
    'Sie haben keine Zahlungsmethode verbunden. Gehen Sie zum Tab "Abrechnungen" und fügen Sie eine Zahlungsmethode hinzu.',
  invoice_data_missing:
    'Sie haben keine abgeschlossenen Daten für die Umsatzsteuerrechnung. Gehen Sie zur Registerkarte "Abrechnungen" und füllen Sie die Daten aus.',
  my_web_template: "Meine Webseite",
  no_websites_info:
    "Sie haben noch keine Websites hinzugefügt, klicken Sie unten, um die erste hinzuzufügen.",
  no_website_info_button: "Neue Website",
  no_mobile_apps_info:
    "Sie haben noch kein mobiles App-Projekt, klicken Sie unten, um das erste hinzuzufügen.",
  no_mobile_apps_info_button: "Erstellen Sie eine mobile App",
  my_mobile_app_template: "Meine mobile-App",
  company_name_2: "Unternehmensname oder Vor- und Nachname",
  go_back: "Zurück",
  new_feature: "Neue Funktion in Calendesk",
  notifications_banner_text:
    "Personalisieren Sie E-Mail, SMS und Push-Benachrichtigungen ganz einfach.",
  edit_now: "Jetzt bearbeiten",
  notification_templates_list: "Vorlagen",
  notification_templates_list_header: "Panel / Benachrichtigungen",
  booking_prepayment: "Neue Buchung - Zahlung erforderlich",
  booking_prepayment_info:
    "Diese Benachrichtigung wird versendet, wenn die gebuchte Leistung kostenpflichtig ist oder die angelegte Buchung den Status „Vorauszahlung“ hat. Die Benachrichtigung wird nicht gesendet, wenn die Einstellung „Buchungsbestätigung“ deaktiviert ist oder wenn beim Erstellen der Buchung die Option „Die Erstellung eines Besuchs nicht melden“ ausgewählt wurde.",
  booking_created: "Buchungsbestätigung",
  booking_created_info:
    'Diese Benachrichtigung wird nach dem Erstellen einer Buchung mit dem Status "Akzeptiert" und nach erfolgter Zahlung gesendet. Eine Benachrichtigung wird nicht gesendet, wenn die Einstellung „Buchungsbestätigung“ deaktiviert ist oder wenn beim Erstellen der Buchung die Option „Die Erstellung eines Besuchs nicht melden“ ausgewählt wurde.',
  booking_updated: "Buchungsänderung",
  booking_updated_info:
    "Diese Benachrichtigung wird gesendet, nachdem eine Buchung geändert wurde (d. h. eine Änderung des Status, des Datums oder der Uhrzeit des Meetings, des Mitarbeiters, des Kunden, des Standorts oder der Dienstleistung vorgenommen wurde). Eine Benachrichtigung wird nicht gesendet, wenn die Einstellung „Buchungsänderung“ deaktiviert ist oder wenn bei der Änderung der Buchung die Option „Änderung/Löschung des Besuchs nicht melden“ ausgewählt wurde.",
  booking_deleted: "Löschung einer Buchung",
  booking_deleted_info:
    "Diese Benachrichtigung wird nach dem Löschen oder Stornieren einer Buchung gesendet. Eine Benachrichtigung wird nicht versendet, wenn der Einstellungstyp „Löschung einer Buchung“ ausgeschaltet ist oder wenn beim Löschen der Buchung die Option „Die Löschung nicht melden“ ausgewählt wurde.",
  booking_reminder: "Buchungserinnerung",
  booking_reminder_info:
    'Die Benachrichtigung wird gemäß der eingestellten Zeit vor der Reservierung an Reservierungen mit dem Status "Akzeptiert" gesendet. Die Benachrichtigung wird nicht gesendet, wenn die Benachrichtigungsart "Reservierungserinnerung" ausgeschaltet ist.',
  account_activate: "Kontoaktivierung - Willkommens-E-Mail",
  account_activate_info:
    "Diese Benachrichtigung wird gesendet, nachdem ein Kunde sein eigenes Konto auf der von Calendesk generierten Seite erstellt hat.",
  "account_password-reset": "Passwort zurücksetzen",
  "account_password-reset_info":
    "Diese Benachrichtigung wird gesendet, nachdem die Passworterinnerungsoption auf der von Calendesk generierten Seite verwendet wurde.",
  newsletter_subscribe: "Newsletter-Abo",
  newsletter_subscribe_info:
    "Diese Benachrichtigung wird gesendet, nachdem die Newsletter-Anmeldeoption auf der von Calendesk generierten Seite verwendet wurde.",
  "simple-store-product-transaction_paid": "Shop - Kaufbestätigung",
  edited: "Bearbeitet",
  "simple-store-product-transaction_paid_info":
    "Die Benachrichtigung wird an die Haupt-E-Mail-Adresse des Kontos gesendet, nachdem Produkte auf der in Calendesk generierten Seite gekauft wurden. Die Benachrichtigung wird nicht gesendet, wenn Produkte während der Reservierung des Besuchs gekauft werden (Benachrichtigungen über Reservierungen enthalten bereits diese Informationen).",
  notification_info:
    "Das Bearbeiten von Benachrichtigungen hilft Ihnen dabei, den Inhalt von Benachrichtigungen an Ihre Anforderungen anzupassen. Sie können die erweiterte Bearbeitung von Benachrichtigungen mithilfe der sogenannten Variablen verwenden oder die Standardeinstellungen belassen. Standardbenachrichtigungen enthalten alle notwendigen Informationen, die Ihre Kunden und Mitarbeiter erhalten sollten.",
  notification_templates_list_for_client: "Kundenbenachrichtigungen",
  notification_templates_list_for_tenant:
    "Benachrichtigungen an Ihren Mitarbeiter",
  notification_templates_list_for_tenant_info:
    "Benachrichtigungen an Mitarbeiter werden an die E-Mail-Adressen der Mitarbeiter gesendet oder, falls diese fehlen, an die primäre Adresse des Kontos (Registerkarte Einstellungen / Ihr Unternehmen).",
  notification_templates_list_information:
    'Im Abschnitt auf der linken Seite können Sie Ihre Benachrichtigungen individuell anpassen. Sie können in Ihren Nachrichten Variablen verwenden. Variablen haben das Format "[NAZWA]". Wir ersetzen einen Wert Ihrer Wahl anstelle der gesamten Variable. Dies könnte der Name der Dienstleistung, der Name Ihres Kunden usw. sein. Klicken Sie einfach auf die blaue Schaltfläche neben der Stelle, an der Sie die Benachrichtigung eingestellt haben, um die Variable auszuwählen.',
  ready: "Fertig",
  notification_templates_list_preview_title: "Vorschau von Beispielnachrichten",
  notification_template_editor_sidebar_email_title: "E-Mail",
  notification_template_editor_sidebar_sms_title: "SMS",
  notification_template_editor_sidebar_push_title: "Push-Benachrichtigung",
  notification_template_editor_sidebar_title: "Titel",
  notification_template_editor_sidebar_text: "Text",
  notification_template_editor_publish_title:
    "Inhalt der Benachrichtigungen ändern",
  notification_template_editor_publish_cancel_title: "Änderungen löschen",
  notification_template_editor_publish_text:
    "Die Änderung wird sofort umgesetzt.",
  notification_template_editor_publish_cancel_text:
    "Die vorgenommenen Änderungen werden gelöscht.",
  notification_template_editor_publish_warning:
    "Dieser Vorgang kann nicht rückgängig gemacht werden!",
  notification_template_editor_publish_confirmation_button:
    "Benachrichtigungen ändern",
  notification_template_editor_publish_cancel_confirmation_button:
    "Änderungen löschen",
  notification_template_editor_sidebar_restore_all:
    "Standardinhalte wiederherstellen",
  notification_template_editor_sidebar_restore_all_information:
    "Die Standardbenachrichtigungen aus dieser Gruppe werden wiederhergestellt.",
  notification_template_editor_sidebar_restore_all_button: "Wiederherstellen",
  booking_prepayment_email_subject_client:
    "Zahlung erforderlich: [BOOKING_START_DATE] [BOOKING_START_TIME] [SERVICE_NAME]",
  booking_prepayment_email_text_client:
    "Hallo,<br><br>Ihre Buchung für [SERVICE_NAME] muss bezahlt werden. <br><br><b>Datum und Uhrzeit:</b><br>[BOOKING_START_DATE] [BOOKING_START_TIME]<br><br><b>Betrag:</b><br>[BOOKING_PRICE]<br><br>[BOOKING_CUSTOM_FIELDS]<br><br><b>Wenn die Zahlung nicht innerhalb von [BOOKING_AWAITING_PAYMENT_FULL_TIME] erfolgt, wird Ihre Buchung gelöscht. Klicken Sie unten, um die Buchung einzusehen und die Zahlung vorzunehmen.</b> <br><br>[MANAGE_CUSTOMER_BOOKING_BUTTON]",
  booking_created_email_subject_client:
    "Bestätigt: [BOOKING_START_DATE] [BOOKING_START_TIME] [SERVICE_NAME]",
  booking_created_email_text_client:
    "Hallo,<br><br>Ihre Buchung für [SERVICE_NAME] wurde bestätigt. <br><br><b>Datum und Uhrzeit:</b><br>[BOOKING_START_DATE] [BOOKING_START_TIME]<br><br><b>Ort:</b><br>[BOOKING_LOCATION] [BOOKING_ONLINE_MEETING_URL]<br><br>[BOOKING_CUSTOM_FIELDS]<br><br>[MANAGE_CUSTOMER_BOOKING_BUTTON]<br>[ADD_TO_CALENDAR_BUTTONS]",
  booking_created_email_subject_tenant:
    "Neue Buchung: [BOOKING_START_DATE] [BOOKING_START_TIME] [SERVICE_NAME]",
  booking_created_email_text_tenant:
    "Hallo,<br><br><b>Es gibt eine neue Buchung für die Dienstleistung:</b><br> [SERVICE_NAME]<br><br><b>Kunde:</b><br>[CUSTOMER_FULL_NAME]<br><br><b>Mitarbeiter:</b><br>[EMPLOYEE_FULL_NAME]<br><br><b>Datum und Uhrzeit:</b><br>[BOOKING_START_DATE] [BOOKING_START_TIME]<br><br><b>Ort:</b><br>[BOOKING_LOCATION] [BOOKING_ONLINE_MEETING_URL]<br><br>[BOOKING_CUSTOM_FIELDS]<br><br>[MANAGE_EMPLOYEE_BOOKING_BUTTON]",
  booking_updated_email_subject_client:
    "Umbuchung: [BOOKING_START_DATE] [BOOKING_START_TIME] [SERVICE_NAME]",
  booking_updated_email_text_client:
    "Hallo,<br><br>Ihre Buchung für [SERVICE_NAME] wurde geändert. <br><br><b>Datum und Uhrzeit:</b><br>[BOOKING_START_DATE] [BOOKING_START_TIME]<br><br><b>Ort:</b><br>[BOOKING_LOCATION] [BOOKING_ONLINE_MEETING_URL]<br><br>[BOOKING_CUSTOM_FIELDS]<br><br>[MANAGE_CUSTOMER_BOOKING_BUTTON]<br>[ADD_TO_CALENDAR_BUTTONS]",
  booking_updated_email_subject_tenant:
    "Umbuchung: [BOOKING_START_DATE] [BOOKING_START_TIME] [SERVICE_NAME]",
  booking_updated_email_text_tenant:
    "Hallo,<br><br><b>Es gab eine Änderung in der Buchung für Dienstleistungen:</b><br> [SERVICE_NAME]<br><br><b>Kunde:</b><br>[CUSTOMER_FULL_NAME]<br><br><b>Mitarbeiter:</b><br>[EMPLOYEE_FULL_NAME]<br><br><b>Datum und Uhrzeit:</b><br>[BOOKING_START_DATE] [BOOKING_START_TIME]<br><br><b>Ort:</b><br>[BOOKING_LOCATION] [BOOKING_ONLINE_MEETING_URL]<br><br>[BOOKING_CUSTOM_FIELDS]<br><br>[MANAGE_EMPLOYEE_BOOKING_BUTTON]",
  booking_deleted_email_subject_client:
    "Buchung storniert: [BOOKING_START_DATE] [BOOKING_START_TIME] [SERVICE_NAME]",
  booking_deleted_email_text_client:
    "Hallo,<br><br>Bitte beachten Sie, dass die Buchung für <b>[SERVICE_NAME]</b> storniert wurde.<br><br><b>Datum und Uhrzeit:</b><br>[BOOKING_START_DATE] [BOOKING_START_TIME]",
  booking_deleted_email_subject_tenant:
    "Buchung storniert: [BOOKING_START_DATE] [BOOKING_START_TIME] [SERVICE_NAME]",
  booking_deleted_email_text_tenant:
    "Hallo,<br><br>Bitte beachten Sie, dass die Buchung für <b>[SERVICE_NAME]</b> storniert wurde.<br><br><b>Klient:</b><br>[CUSTOMER_FULL_NAME]<br><br><b>Mitarbeiter:</b><br>[EMPLOYEE_FULL_NAME]<br><br><b>Datum und Uhrzeit:</b><br>[BOOKING_START_DATE] [BOOKING_START_TIME]<br><br>[MANAGE_EMPLOYEE_BOOKING_BUTTON]",
  booking_reminder_email_subject_client:
    "Buchungserinnerung: [BOOKING_START_DATE] [BOOKING_START_TIME] [SERVICE_NAME]",
  booking_reminder_email_text_client:
    "Hallo,<br><br>Erinnerung an die Buchung für die Dienstleitung <b>[SERVICE_NAME].</b><br><br><b>Datum und Uhrzeit:</b><br>[BOOKING_START_DATE] [BOOKING_START_TIME]<br><br><b>Ort:</b><br>[BOOKING_LOCATION] [BOOKING_ONLINE_MEETING_URL]<br><br>[BOOKING_CUSTOM_FIELDS]<br><br>[MANAGE_CUSTOMER_BOOKING_BUTTON]",
  booking_reminder_email_subject_tenant:
    "Buchungserinnerung: [BOOKING_START_DATE] [BOOKING_START_TIME] [SERVICE_NAME]",
  booking_reminder_email_text_tenant:
    "Hallo,<br><br>Erinnerung an die Buchung für die Dienstleitung:<br> [SERVICE_NAME]<br><br><b>Kunde:</b><br>[CUSTOMER_FULL_NAME]<br><br><b>Mitarbeiter:</b><br>[EMPLOYEE_FULL_NAME]<br><br><b>Datum und Uhrzeit:</b><br>[BOOKING_START_DATE] [BOOKING_START_TIME]<br><br><b>Ort:</b><br>[BOOKING_LOCATION] [BOOKING_ONLINE_MEETING_URL]<br><br>[BOOKING_CUSTOM_FIELDS]<br><br>[MANAGE_EMPLOYEE_BOOKING_BUTTON]",
  booking_created_push_subject_client: "Vielen Dank für Ihre Buchung",
  booking_created_push_text_client:
    "[SERVICE_NAME] bei [COMPANY_NAME] am [BOOKING_START_DATE] um [BOOKING_START_TIME].",
  booking_created_push_subject_tenant: "[COMPANY_NAME] - Neue Buchung",
  booking_created_push_text_tenant:
    "Dienstleistung: [SERVICE_NAME], Kunde: [CUSTOMER_FULL_NAME], Datum: [BOOKING_START_DATE], Uhrzeit: [BOOKING_START_TIME].",
  booking_updated_push_subject_client: "Ihre Buchung wurde geändert",
  booking_updated_push_text_client:
    "[SERVICE_NAME] bei [COMPANY_NAME] am [BOOKING_START_DATE] um [BOOKING_START_TIME], Status: [BOOKING_STATUS].",
  booking_updated_push_subject_tenant: "[COMPANY_NAME] - Buchungsänderung",
  booking_updated_push_text_tenant:
    "Dienstleistung: [SERVICE_NAME], Kunde: [CUSTOMER_FULL_NAME], Datum: [BOOKING_START_DATE], Uhrzeit: [BOOKING_START_TIME], Status: [BOOKING_STATUS].",
  booking_deleted_push_subject_client: "Die Buchung wurde gelöscht",
  booking_deleted_push_text_client:
    "[SERVICE_NAME] bei [COMPANY_NAME] am [BOOKING_START_DATE] um [BOOKING_START_TIME].",
  booking_deleted_push_subject_tenant:
    "[COMPANY_NAME] - Die Buchung wurde gelöscht",
  booking_deleted_push_text_tenant:
    "Dienstleistung: [SERVICE_NAME], Kunde: [CUSTOMER_FULL_NAME], Datum: [BOOKING_START_DATE], Uhrzeit: [BOOKING_START_TIME].",
  booking_reminder_push_subject_client: "Buchungserinnerung",
  booking_reminder_push_text_client:
    "[SERVICE_NAME] bei [COMPANY_NAME] am [BOOKING_START_DATE] um [BOOKING_START_TIME].",
  booking_reminder_push_subject_tenant: "[COMPANY_NAME] - Buchungserinnerung",
  booking_reminder_push_text_tenant:
    "Dienstleistung: [SERVICE_NAME], Kunde: [CUSTOMER_FULL_NAME], Datum: [BOOKING_START_DATE], Uhrzeit: [BOOKING_START_TIME].",
  booking_created_sms_text_client:
    "Vielen Dank für Ihre Buchung für [SERVICE_NAME] bei [COMPANY_NAME] am [BOOKING_START_DATE] um [BOOKING_START_TIME].",
  booking_created_sms_text_tenant:
    "[COMPANY_NAME] - Neue Buchung: [SERVICE_NAME]. Kunde: [CUSTOMER_FULL_NAME], Datum: [BOOKING_START_DATE], Uhrzeit: [BOOKING_START_TIME].",
  booking_updated_sms_text_client:
    "Die Buchung bei [SERVICE_NAME] in [COMPANY_NAME] wurde geändert. Am [BOOKING_START_DATE] um [BOOKING_START_TIME], Status: [BOOKING_STATUS].",
  booking_updated_sms_text_tenant:
    "[COMPANY_NAME] - Buchungsänderung: [SERVICE_NAME]. Kunde: [CUSTOMER_FULL_NAME], Datum: [BOOKING_START_DATE], um.: [BOOKING_START_TIME], Status: [BOOKING_STATUS].",
  booking_deleted_sms_text_client:
    "Die Buchung für [SERVICE_NAME] bei [COMPANY_NAME] wurde gelöscht. Am [BOOKING_START_DATE] um [BOOKING_START_TIME].",
  booking_deleted_sms_text_tenant:
    "[COMPANY_NAME] - Buchungen entfernt: [SERVICE_NAME]. Kunde: [CUSTOMER_FULL_NAME], Datum: [BOOKING_START_DATE], Uhrzeit: [BOOKING_START_TIME].",
  booking_reminder_sms_text_client:
    "Erinnerung an den Besuch bei [COMPANY_NAME] am [BOOKING_START_DATE] um [BOOKING_START_TIME]. Tel.: [COMPANY_PHONE].",
  booking_reminder_sms_text_tenant:
    "[COMPANY_NAME] - Erinnerung an die Buchung bei [SERVICE_NAME]. Kunde: [CUSTOMER_FULL_NAME], Datum: [BOOKING_START_DATE], Uhrzeit: [BOOKING_START_TIME].",
  account_activate_email_subject_client:
    "Bestätigung der Registrierung bei [COMPANY_NAME]",
  account_activate_email_text_client:
    "Willkommen bei [COMPANY_NAME]. Wir freuen uns sehr, Sie kennenzulernen. Sie sind nur einen Schritt davon entfernt, die Funktionalitäten zu nutzen, die unsere Dienste so benutzerfreundlich machen. Schließen Sie Ihre Registrierung ab, indem Sie Ihr Konto aktivieren.",
  account_activate_email_cta_button_client: "Konto aktivieren",
  "account_password-reset_email_subject_client":
    "Ändern des Passworts auf der Website [COMPANY_NAME]",
  "account_password-reset_email_text_client":
    "Hi! Wir haben Ihre Anfrage zur Änderung des Passworts für das Konto auf der Website von [COMPANY_NAME] erhalten. Um Ihr Passwort zurückzusetzen, klicken Sie unten.",
  "account_password-reset_email_cta_button_client": "Passwort erstellen",
  newsletter_subscribe_email_subject_client:
    "Bestätigung des Newsletter-Abos von [COMPANY_NAME]",
  newsletter_subscribe_email_text_client:
    "Klicken Sie unten, um zu bestätigen, dass Sie den Newsletter von [COMPANY_NAME] abonnieren möchten. Wenn Sie diese Nachricht versehentlich erhalten haben, können Sie sie ignorieren.",
  newsletter_subscribe_email_cta_button_client: "Bestätigen",
  "simple-store-product-transaction_paid_email_subject_client":
    "Vielen Dank für Ihren Einkauf [PRODUCT_NAME]",
  "simple-store-product-transaction_paid_email_text_client":
    "Das ist die Bestätigung, dass die Mittel eingegangen sind. Vielen Dank für Ihren Kauf [PRODUCT_NAME]. Preis: [PRODUCT_PRICE], Anzahl: [PRODUCT_QUANTITY]. Zusätzliche Information: [PRODUCT_DESCRIPTION]",
  "simple-store-product-transaction_paid_email_subject_tenant":
    "Neuer Kauf [PRODUCT_NAME]",
  "simple-store-product-transaction_paid_email_text_tenant":
    "Ihr Produkt [PRODUCT_NAME] wurde gekauft von [CUSTOMER_FULL_NAME]. Preis: [PRODUCT_PRICE], Anzahl: [PRODUCT_QUANTITY]. Zusätzliche Information: [PRODUCT_DESCRIPTION]",
  employee_example_name: "John",
  employee_example_surname: "Fleißig",
  employee_example_fullname: "John Fleißig",
  customer_example_name: "Anna",
  customer_example_surname: "Schmitt",
  customer_example_fullname: "Anna Schmitt",
  service_example_name: "ABC-Dienstleistung",
  service_example_name_2: "Online-Beratung",
  phone_number_is_missing: "Telefonnummer fehlt",
  company_address_is_missing: "Unternehmensadresse fehlt",
  sms_title: "Nachricht",
  email_subject: "Betreff",
  push_now_indicator: "jetzt",
  variable_customer_full_name: "Vor- und Nachname des Kunden",
  variable_customer_id: "Kundennummer",
  variable_customer_name: "Vorname des Kunden",
  variable_customer_surname: "Name des Kunden",
  variable_customer_email: "E-Mail des Kunden",
  variable_customer_phone: "Telefon des Kunden",
  variable_employee_id: "Mitarbeiter-ID-Nummer",
  variable_employee_name: "Vorname des Mitarbeiters",
  variable_employee_email: "E-Mail des Mitarbeiters",
  variable_employee_phone: "Telefon des Mitarbeiters",
  variable_employee_surname: "Name des Mitarbeiters",
  variable_employee_full_name: "Vor- und Nachname des Mitarbeiters",
  variable_booking_id: "Buchungs-ID-Nummer",
  variable_booking_start_date: "Buchungsdatum",
  variable_booking_start_time: "Zeitpunkt der Buchung",
  variable_booking_end_date: "Enddatum der Buchung",
  variable_booking_end_time: "Endzeitpunkt der Buchung",
  variable_service_id: "ID-Dienstleistungsnr",
  variable_service_name: "Name der Dienstleistung",
  variable_booking_price: "Dienstleistungspreise",
  variable_company_name: "Unternehmensname",
  variable_company_phone: "Telefonnummer des Unternehmens",
  variable_company_address: "Adresse des Unternehmens",
  variable_booking_status: "Buchungsstatus",
  variable_booking_payment_status: "Zahlungsstatus der Buchung",
  variable_newsletter_email: "E-Mail-Adresse",
  variable_unsubscribe_url: "Schreib mich ab url",
  variable_product_id: "Produkt-ID-Nummer",
  variable_product_name: "Produktname",
  variable_product_description: "Zusätzliche Produktinformationen",
  variable_product_price: "Produktpreis",
  variable_product_quantity: "Stückzahl des Produktes",
  variable_product_list: "Liste der gekauften Produkte",
  variable_booking_location: "Standort",
  variable_booking_time_zone: "Zeitzone",
  variable_booking_recurrence_description:
    "Beschreibung der zyklischen Reservierung",
  variable_online_meeting_url: "Link zum Online-Meeting",
  variable_booking_control_url: "Buchungs-URL",
  variable_booking_created_by_user_id: "Ersteller-ID-Nummer",
  variable_booking_updated_by_user_id: "ID-Nummer des letzten Bearbeiters",
  variable_booking_created_by: "Name des Erstellers",
  variable_booking_updated_by: "Name des letzten Bearbeiters",
  variable_booking_custom_fields: "Felder des Buchungsformulars",
  add_variable_info_text: "Klicken Sie hier, um die Variable hinzuzufügen",
  rename: "Unbenennen",
  hide: "Verstecken",
  change_url: "Verknüpfung ändern",
  back: "Zurück",
  your_pictures: "Ihre Galerie",
  photo_library_unsplash: "Fotobibliothek (kostenlos)",
  search_for_photos: "Suche in Millionen von Bildern",
  search_for_photos_hint: "Bei Unsplash können Sie nur auf Englisch suchen.",
  photos_by_unsplash: "Bilder von Unsplash",
  photo_by_on_unsplash: "Bildautor @{name} von Unsplash",
  unsplash_photo_added_to_gallery:
    "Das Bild ist jetzt in Ihrer Galerie verfügbar.",
  for_example_unsplash: "z.B. Blumen",
  new_website_builder_title: "Neuer Webeditor",
  website_builder_banner_text:
    "Jetzt ist es da! Erstellen Sie Ihre Website mit dem neuen Editor!",
  check_now: "Jetzt prüfen",
  // Color picker
  cp_alert_p1: "Zu geringer Kontrast gewählt!",
  cp_alert_p2: "Das Element wird schlecht lesbar sein.",
  // Website builder
  wb_website_builder: "Website-Editor",
  wb_move_up: "Nach oben verschieben",
  wb_move_down: "Nach unten verschieben",
  wb_auto_save_info: "Änderungen werden automatisch gespeichert",
  wb_pages: "Unterseiten",
  wb_add_new_section: "Abschnitt hinzufügen",
  wb_select_new_section: "Abschnitt auswählen",
  wb_add_new_section_info:
    "Wählen Sie einen Abschnitt aus dem Menü auf der linken Seite",
  wb_add_new_page: "Unterseite hinzufügen",
  wb_add_page_dialog_type_page_name_label: "Name z. B. Dienstleistungen",
  wb_add_page_dialog_type_page_name_blog_label: "Name z.B. Blog",
  wb_add_page_dialog_type_page_description:
    "Beschreibung (sichtbar für Suchmaschinen wie Google)",
  wb_add_page_dialog_type_page_description_hint:
    "Geben Sie eine einzigartige, kurze Beschreibung des Inhalts Ihrer Seite ein. Diese Beschreibung wird in den Suchergebnissen von Suchmaschinen wie Google unter dem Titel Ihrer Seite angezeigt.",
  wb_add_page_dialog_type_page_path_label:
    "Adresse nach Domäne z.B. Dienstleistungen",
  wb_add_page_dialog_type_page_path_blog_label:
    "Adresse nach der Domain z.B. Blog",
  wb_add_page_dialog_type_main_page: "Startseite",
  wb_add_page_dialog_type_page: "Unterseite",
  wb_add_page_dialog_type_redirect: "Umleitung (externer Link)",
  wb_add_page_dialog_type_blog_handy: "Blog",
  wb_add_page_dialog_type_redirect_url: "Umleitung der Webadresse",
  wb_add_page_dialog_type_blog_handy_id:
    "BlogHandy Id z.B. 609wl61ZYL0ArTAFR23",
  wb_blog_handy_description:
    "BlogHandy ist eine externe Plattform, die es Ihnen ermöglicht, einfach einen Blog zu führen. BlogHandy kann einfach mit der www-Seite in Calendesk integriert werden.",
  wb_blog_handy_info_1:
    "Um einen Blog zu Ihrer Calendesk-Seite hinzuzufügen, befolgen Sie die unten stehenden Anweisungen:",
  wb_blog_handy_info_2:
    "Klicken Sie unten und registrieren Sie sich auf der BlogHandy-Plattform.",
  wb_blog_handy_info_3:
    "Kopieren Sie den Blog-Identifier von Ihrem BlogHandy-Konto und fügen Sie ihn in das unten stehende Feld ein.",
  wb_blog_handy_info_4:
    "Herzlichen Glückwunsch! 🎉 Jeder Blogbeitrag, der auf BlogHandy veröffentlicht wird, erscheint automatisch auf Ihrer Calendesk-Seite.",
  wb_blog_handy_signup_button_title: "Registrieren Sie sich bei BlogHandy",
  wb_general: "Allgemein",
  wb_style: "Stil",
  wb_section: "Seitenabschnitt",
  wb_color_primary: "Schaltflächen und wichtige Elemente",
  wb_color_bg: "Erster Hintergrund",
  wb_color_text: "Text auf dem ersten Hintergrund",
  wb_color_bg_2: "Zweiter Hintergrund",
  wb_color_text_2: "Text auf dem zweiten Hintergrund",
  wb_google_tag_id: "Google Tag ID",
  wb_website_description: "Beschreibung der Seite für Robots (SEO)\n",
  wb_title: "Überschrift",
  wb_text: "Inhalt",
  wb_subtitle: "Untertitel",
  wb_description: "Beschreibung",
  wb_image: "Grafik",
  wb_image_1: "Grafik 1",
  wb_image_2: "Grafik 2",
  wb_image_3: "Grafik 3",
  wb_image_4: "Grafik 4",
  wb_background: "Hintergrund",
  wb_category_section_navbar_1: "Überschrift mit Logo",
  wb_category_section_navbar_2: "Überschrift ohne Logo",
  wb_category_section_footer: "Fußzeile",
  wb_category_section_employees: "Mitarbeiter",
  wb_category_section_services: "Dienstleistungen",
  wb_category_section_booking: "Termin buchen",
  wb_category_section_about_us: "Über uns",
  wb_category_section_feature: "Universal",
  wb_category_section_review: "Optionen",
  wb_category_section_other: "Andere",
  wb_category_section_header: "Überschrift",
  wb_category_section_newsletter: "Newsletter",
  wb_category_section_form: "Kontaktformular",
  wb_category_section_cta: "Aufruf zum Handeln (CTA)",
  wb_decrease_section: "Verkleinern",
  wb_increase_section: "Vergrößern",
  wb_company_name: "Unternehmensname",
  wb_login_modal: "Anmeldung",
  wb_login_subtitle: "Untertitel",
  wb_sign_up: "Konto erstellen",
  wb_login: "Einloggen",
  wb_sign_up_modal: "Registrierung",
  wb_menu_bookings: "Buchungen",
  wb_menu_payments: "Zahlungen",
  wb_menu_settings: "Einstellungen",
  wb_menu_logout: "Abmelden",
  wb_forgotten_password_modal: "Passwort erinnern",
  wb_forgotten_password_confirmation_modal: "E-Mail-Bestätigungen",
  wb_forgotten_password_title: "Titel",
  wb_forgotten_password_subtitle: "Untertitel",
  wb_submit_button: "Schaltfläche Bestätigen",
  wb_forgotten_password_modal_confirmation_title: "Titel",
  wb_forgotten_password_modal_confirmation_subtitle: "Untertitel",
  wb_company_address: "Adresse",
  wb_company_email: "E-Mail-Adresse",
  wb_company_phone: "Telefon",
  wb_menu_title: "Menü Titel",
  wb_account_title: "Profil",
  wb_signup: "Registieren",
  wb_profile: "Buchungen",
  wb_settings: "Einstellungen",
  wb_company_slogan: "Schlagwort",
  wb_service_label: "Dienstleistungsetikett",
  wb_employee_label: "Mitarbeiter-Etikette",
  wb_employee_info_label: "Text über dem Mitarbeiterfeld",
  wb_booking_button: "Schaltfläche Buchung",
  wb_page_list: "Menü Bearbeiten",
  wb_menu_column_1: "Menü 1",
  wb_menu_column_2: "Menü 2",
  wb_publish_confirm_dialog_title: "Soll die Website veröffentlicht werden?",
  wb_publish_confirm_dialog_text:
    "Die Website wird innerhalb einer Minute online sein.",
  wb_publish_success_text:
    "Herzlichen Glückwunsch! Ihre Website wird in kürzester Zeit online sein.",
  wb_more_pages_button: "Weitere Seiten",
  wb_sign_up_terms_info:
    "Soll der Vermerk über die Annahme der Allgemeinen Geschäftsbedingungen angezeigt werden?",
  wb_select_data_label: "Datum auswählen",
  wb_edit_page: "Seite bearbeiten",
  wb_remove_page_confirm_dialog_title: "Soll die Website gelöscht werden?",
  wb_remove_page_confirm_dialog_text: "Die Seite wird gelöscht.",
  wb_remove_page_success_text: "Die Seite wurde gelöscht.",
  wb_remove_confirm_dialog_title: "Sind Sie ganz sicher?",
  wb_remove_confirm_dialog_text:
    "Diese Änderung kann nicht rückgängig gemacht werden!",
  wb_remove_success_text: "Löschung erfolgreich abgeschlossen",
  wb_employees_title: "Unser Team",
  wb_employees_no_data:
    "Fügen Sie Mitarbeiter im Calendesk-Bedienfeld hinzu, um eine Vorschau zu sehen.",
  wb_services_no_data:
    "Fügen Sie im Calendesk-Panel Dienstleistungen hinzu, um eine Vorschau zu sehen.",
  wb_employee_list: "Spezialisten",
  wb_service_list: "Dienstleistungen",
  wb_items_selector_info: "Klicken Sie unten, um Elemente hinzuzufügen",
  wb_change: "Ersetzen",
  wb_replace_navbar: "Kopfzeile ersetzen",
  wb_replace_footer: "Fußzeile ersetzen",
  wb_main_pages_number: "Anzahl der Hauptregisterkarten",
  wb_button: "Schaltfläche",
  wb_domain_example: "z.B. meinefirma.de",
  wb_no_domain_info: "Die Domäne wurde nicht eingerichtet",
  wb_book_online: "Schaltfläche Buchung",
  wb_button_title: "Schaltfläche",
  wb_select_subpage: "Unterseite",
  wb_features: "Eigenschaften",
  wb_add_button: "Schaltfläche hinzufügen",
  wb_new_button: "Neue Schaltfläche",
  wb_button_edit_menu_redirect: "Umleitung auswählen",
  wb_button_edit_menu_style: "Stil und Größe einstellen",
  wb_button_outlined: "Umriss",
  wb_button_rounded: "Abgerundet",
  wb_button_size: "Größe",
  wb_button_dark: "Dunkel",
  wb_redirect: "Umleitung",
  wb_add_sections_feature: "Element hinzufügen",
  wb_dummy_text_about_us: "Über uns",
  wb_dummy_text_service: "Dienstleistung",
  wb_dummy_text_select_service: "Dienstleistung auswählen",
  wb_dummy_text_select_employee: "Person auswählen",
  wb_dummy_selected_services: "Ausgewählte Dienstleistungen",
  wb_dummy_text_select_date: "Datum wählen",
  wb_dummy_text_select_date_and_hour: "Datum und Zeit wählen",
  wb_dummy_text_services: "Unsere Dienstleistungen",
  wb_dummy_text_book_now: "Buchen",
  wb_dummy_text_employees: "Unser Team",
  wb_dummy_text_more: "Mehr anzeigen",
  wb_dummy_text_buy_now: "Jetzt kaufen",
  wb_dummy_text_regulations: "Allgemeine Bestimmungen",
  wb_dummy_text_privacy_policy: "Datenschutzbestimmungen",
  wb_dummy_text_reviews_title: "Feedback unserer Kunden",
  wb_dummy_text_reviews_full_name: "Max Mustermann",
  wb_dummy_text_review_example:
    "Dieser Ort ist ein echter Hammer, ich nutze ihre Dienste schon seit langem und sie überraschen mich immer noch! Ich kann es nur empfehlen :)",
  wb_dummy_text_booking_title:
    "Buchen Sie einen Termin bei uns in wenigen Minuten, ohne Ihr Haus zu verlassen und komplett online.",
  wb_dummy_text_1:
    "Moderner Ort in der Mitte Berlins. Immer für Sie da! Bei uns arbeiten nur die Besten der Besten. Wir vergessen nicht, uns weiterzubilden und Wissen zu erwerben, das wir später an Sie weitergeben können. Begeben Sie sich in unsere Hände. Wir verwenden modernste Methoden. Sie können einen Termin komplett online vereinbaren - nutzen Sie einfach die mobile App, wo Sie die wichtigsten Informationen von uns erhalten.",
  wb_dummy_text_2:
    "Erkunden Sie die Möglichkeiten, die Ihnen unsere Dienstleistungen bieten",
  wb_dummy_text_3: "Was bieten wir an?",
  wb_dummy_text_4: "Worüber würden Sie gerne sprechen?",
  wb_dummy_text_5: "Bitte geben Sie Ihren Namen unten ein",
  wb_dummy_text_6:
    "Dies ist ein Beispiel für eine Dienstleistungsbeschreibung. Sie legen sie im Calendesk Administrationsbereich fest, wenn Sie die Dienstleistungen bearbeiten. Sie können einen beliebigen Text für verschiedene Angebote wählen.",
  wb_dummy_text_title: "Beispieltext",
  wb_dummy_subject_title: "Nachricht aus dem Formular auf der Website",
  wb_dummy_form_sent_success_title:
    "Vielen Dank, das Formular wurde korrekt übermittelt.",
  wb_dummy_form_sent_success_description:
    "Wir werden Sie so bald wie möglich kontaktieren.",
  wb_dummy_text_newsletter_1:
    "Informationen über neue Dienstleistungen und Produkte direkt an Ihre E-Mail-Adresse",
  wb_dummy_text_newsletter_2:
    "Abonnieren Sie unseren Newsletter, um auf dem Laufenden zu bleiben",
  wb_dummy_text_newsletter_3: "Abonnieren",
  wb_dummy_text_newsletter_4: "E-Mail-Adresse",
  wb_dummy_text_newsletter_5:
    "Herzlichen Dank! Ein Aktivierungslink wurde an Ihre E-Mail-Adresse gesendet.",
  wb_dummy_text_newsletter_6: "Mehr als Sie glauben",
  wb_regulations_info:
    "Der Inhalt der Allgemeinen Bedingungen kann im Calendesk-Panel unter Einstellungen / Ihr Unternehmen bearbeitet werden.",
  wb_privacy_policy_info:
    "Der Inhalt der Datenschutzrichtlinie kann im Calendesk-Panel unter Einstellungen / Ihr Unternehmen bearbeitet werden.",
  wb_regulations_page: "Seite der Allgemeinen Bedingungen",
  wb_privacy_policy_page: "Seite zur Datenschutzpolitik",
  wb_login_enabled: "Anmeldung ist aktiv",
  wb_booking_confirmation: "Buchungsbestätigung",
  wb_booking_created_summary: "Bestätigung des Nachrichtenversands",
  wb_booking_created_payment_summary: "Informationen zur Zahlungspflicht",
  wb_creator_chat_1:
    "Hallo &#128075;. Ich bin Ihr virtueller Webdesign-Assistent. Ich kann Ihnen helfen, Ihre Website in wenigen Minuten einzurichten.",
  wb_creator_chat_2: "Wählen Sie Ihre Branche",
  wb_creator_chat_3:
    "Herzlichen Dank! Der erste Schritt ist getan &#128588;. Nun ist es an der Zeit, die Elemente auszuwählen, die auf Ihrer neuen Website erscheinen sollen.",
  wb_creator_chat_4:
    "Wählen Sie den Stil aus, der auf die Seite angewendet werden soll. Die Farben können später beliebig geändert werden.",
  wb_creator_chat_5:
    "Es ist fast fertig! Sie können mehrere Entwürfe der Seite haben und sie nach Bedarf ändern &#129321;. Wie soll die aktuelle Skizze genannt werden?",
  wb_creator_chat_6:
    "Erledigt &#128079;! Das haben Sie großartig gemacht. Die Seite wird gerade vorbereitet, geben Sie mir eine Minute.... &#128640;&#128640;",
  wb_creator_chat_6a: "Es wird großartig sein &#129321;",
  wb_creator_chat_6b: "Ich bin fast fertig ...",
  wb_creator_chat_6c: "Nur noch ein paar Codezeilen und fertig ...",
  wb_select_draft_name:
    "Wie soll der aktuelle Entwurf der Seite genannt werden?",
  wb_draft_name_text_field_label: "Website Name",
  wb_chat_with_us: "Support-Chat",
  wb_selected_n_items: "Ausgewählte Elemente: @{amount}.",
  wb_business_1: "Friseursalon",
  wb_business_2: "Wellness-Salon",
  wb_business_3: "Beauty-Salon",
  wb_business_4: "Diätassistent/in",
  wb_business_5: "Nachhilfe",
  wb_business_6: "Kinderbetreuung",
  wb_business_7: "Geschäftliche Termine",
  wb_business_8: "Magistrat",
  wb_business_9: "Finanzdienstleistungen",
  wb_business_10: "Escape room",
  wb_business_11: "Juristische Dienstleistungen",
  wb_business_12: "Medizin",
  wb_business_13: "Fahrschule",
  wb_business_14: "Fotostudio",
  wb_business_15: "Psychologe/-in",
  wb_business_16: "Beratungsleistungen",
  wb_business_17: "Coaching",
  wb_business_18: "Tierpflege",
  wb_business_19: "Innenarchitektur",
  wb_business_20: "Reinigungsdienstleistungen",
  wb_business_21: "Sport",
  wb_business_22: "Personal Trainer",
  wb_business_23: "Fitnessstudio",
  wb_business_24: "Fitness",
  wb_business_25: "Yoga",
  wb_business_26: "Tennisklub",
  wb_business_27: "Veranstaltungsorte",
  wb_business_28: "Restaurant",
  wb_business_29: "Physiotherapie",
  wb_business_30: "Sonstiges",
  wb_website_element_1: "Anmeldung und Registrierung von Kunden",
  wb_website_element_2: "Online Buchungen",
  wb_website_element_3: "Dienstleistungen",
  wb_website_element_4: "Über uns",
  wb_website_element_5: "Team",
  wb_website_element_6:
    "Allgemeine Geschäftsbedingungen und Datenschutzerklärung",
  wb_website_element_7: "Bildergalerie",
  wb_website_element_8: "Newsletter",
  wb_website_element_tooltip_1:
    "Wir ermöglichen Ihren Kunden, ein Konto zu erstellen und sich einzuloggen.",
  wb_website_element_tooltip_2:
    "Es wird ein Abschnitt für Online-Buchungen hinzugefügt.",
  wb_website_element_tooltip_3:
    "Ein Abschnitt und eine Unterseite mit Ihren Dienstleistungen werden hinzugefügt.",
  wb_website_element_tooltip_4:
    "Ein Abschnitt mit Informationen über Ihr Unternehmen wird hinzugefügt.",
  wb_website_element_tooltip_5:
    "Ein Abschnitt und eine Unterseite mit Ihren Mitarbeitern werden hinzugefügt.",
  wb_website_element_tooltip_6:
    "Es wird eine Unterseite mit den Nutzungsbedingungen und der Datenschutzerklärung hinzugefügt. Das Ganze wird aus dem Calendesk-Panel heruntergeladen.",
  wb_website_element_tooltip_7:
    "Ein Abschnitt mit Fotogalerie wird hinzugefügt.",
  wb_website_element_tooltip_8: "Ein Newsletter-Abschnitt wird hinzugefügt.",
  wb_website_style_1: "Seesturm",
  wb_website_style_2: "Meeresstahl",
  wb_website_style_3: "Pastellpulver",
  wb_website_style_4: "Nebliger Mohn",
  wb_website_style_5: "Waldwelt",
  wb_website_style_6: "Honig Dämmerung",
  wb_website_style_7: "Sonniges Lächeln",
  wb_website_style_8: "Roter Kuss",
  wb_website_style_9: "Gesundes Grün",
  wb_website_style_10: "Regenwolke",
  wb_website_style_11: "Blaubeeren in Schokolade",
  wb_website_style_12: "Orangenstaub",
  wb_draft_name_example: "Meine Seite - @{template}",
  wb_default_login_text:
    "Sie müssen eingeloggt sein, um einen Termin zu buchen.",
  wb_default_forgotten_password_title: "Manchmal vergisst jeder sein Passwort",
  wb_default_forgotten_password_subtitle:
    "Geben Sie die E-Mail-Adresse ein, die Sie bei der Registrierung verwendet haben, um Anweisungen zur Änderung Ihres Passworts zu erhalten.",
  wb_default_forgotten_password_modal_confirmation_title:
    "E-Mail-Posteingang prüfen",
  wb_default_forgotten_password_modal_confirmation_subtitle:
    "Wenn die E-Mail-Adresse im System vorhanden ist, wurde soeben eine E-Mail mit Anweisungen zum Ändern des Passworts an diese Adresse gesendet.",
  wb_default_booking_created_summary:
    "Eine Buchungsbestätigung wurde an Ihre E-Mail-Adresse geschickt.",
  wb_default_address: "Berlin, Hauptstraße 1, 10101",
  wb_default_email: "hans.bauer@calendesk.com",
  wb_default_product_name: "Beispielprodukt",
  wb_default_product_description: "Dies sind zusätzliche Produktinformationen.",
  wb_default_phone: "+49 22 200 20 20",
  wb_default_menu_title: "Menü",
  wb_default_profile_title: "Profil",
  wb_main_page_name: "Startseite",
  wb_main_page_url: "Startseite",
  wb_employees_page_name: "Über uns",
  wb_team_page_name: "Unser Team",
  wb_employees_page_url: "über-uns",
  wb_team_page_url: "unser-Team",
  wb_services_page_name: "Dienstleistungen",
  wb_services_page_name_2: "Angebot",
  wb_services_page_url: "Dienstleistungen",
  wb_services_page_url_2: "Angebot",
  wb_regulations_page_name: "Allgemeine Bestimmungen",
  wb_regulations_page_url: "allgemeine Bestimmungen",
  wb_privacy_policy_page_name: "Datenschutzerklärung",
  wb_privacy_policy_page_url: "Datenschutzerklärung",
  wb_dark_mode: "Dark Mode",
  wb_facebook: "Facebook-URL",
  wb_instagram: "Instagram-URL",
  wb_twitter: "Twitter-URL",
  wb_youtube: "YouTube-URL",
  wb_section_missing_question:
    "Fehlender Abschnitt? Schreiben Sie uns, wir werden es für Sie machen!",
  wb_employees_select_section_info:
    "Die Beschreibungen der Mitarbeiter und ihre Fotos können im Calendesk-Panel bearbeitet werden.",
  wb_edit_employees_button_title: "Mitarbeiter bearbeiten",
  wb_services_select_section_info:
    "Die Beschreibungen und Bilder der Dienstleistungen können im Calendesk-Panel bearbeitet werden.",
  wb_edit_services_button_title: "Dienstleistungen bearbeiten",
  wb_reload_button_title: "Neu laden",
  wb_category_section_photo_gallery: "Bildergalerie",
  wb_hide_arrows: "Pfeile verstecken",
  wb_hide_delimiters: "Die Schalter verstecken",
  wb_interval: "Geschwindigkeit der Änderung in Millisekunden",
  wb_cycle: "Automatisches Umschalten",
  wb_invert_colors: "Farben invertieren",
  wb_website_disabled_success:
    "Die Website wurde deaktiviert und wird in wenigen Minuten nicht mehr im Internet zu finden sein.",
  wb_set_as_main_website: "Als Hauptwebsite festlegen",
  wb_set_as_main_website_success: "Die Hauptseite wurde korrekt eingerichtet.",
  wb_main_website: "Startseite",
  new_content_available:
    'Die Seite wurde aktualisiert. Klicken Sie auf "Aktualisieren", um die Seite neu zu laden.',
  refresh_button_title: "Neu laden",
  wb_newsletter_input_placeholder: "Tipp",
  wb_newsletter_success_message: "Nachricht zum Senden eines Links",
  newsletter_delete_success:
    "Die ausgewählten Datensätze wurden korrekt gelöscht",
  remove_newsletter_record: "E-Mail-Adresse löschen",
  remove_newsletter_records: "E-Mail-Adressen löschen",
  verified_at: "Verifiziert",
  email_is_valid: "E-Mail-Adresse bestätigt",
  email_is_not_valid: "E-Mail nicht bestätigt",
  newsletters: "Newsletter",
  push: "Push",
  push_tooltip: "Der Benutzer verwendet die mobile App",
  send_customer_notification_title: "Nachricht an Kunden senden",
  send_customers_notification_title: "Nachricht an die Kunden senden",
  send_employee_notification_title: "Nachricht an den Mitarbeiter senden",
  send_employees_notification_title: "Nachricht an die Mitarbeiter senden",
  send_customer_notification_number_info: "Empfängeranzahl",
  notification_type_1: "E-Mail",
  notification_type_2: "SMS",
  notification_type_3: "Push-Benachrichtigung",
  notification_type_title: "Benachrichtigungstyp",
  notification_subject: "Titel",
  notification_content: "Inhalt",
  send: "Senden",
  send_customer_notification_confirm_title:
    "Sind Sie sicher, dass Sie eine Nachricht an ausgewählte Personen senden möchten?",
  send_customer_notification_confirm_info:
    "Sie senden eine Nachricht Typ  „@{type}”. Anzahl der Empfänger: @{amount}.",
  download_csv_title: "CSV herunterladen",
  download_vcf_title: "VCF herunterladen (vCard)",
  download_xlsx_title: "XLSX herunterladen (Microsoft Excel)",
  download_xls_title: "XLS herunterladen (Microsoft Excel)",
  download_ods_title: "ODS herunterladen (Open Office, LibreOffice)",
  download_html_title: "HTML herunterladen",
  customer_notifications: "Verlauf",
  customer_type: "Empfängertyp",
  customer_type_1: "Kunde",
  customer_type_2: "Newsletter",
  customer_type_3: "Mitarbeiter",
  recipients_count_title: "Empfängeranzahl",
  display_customer_notification_title: "Vorschau der Nachricht",
  expand: "Erweitern",
  collapse: "Weniger",
  reverse_charge_info:
    "Die MwSt. wird in der Rechnung nicht ausgewiesen (Reverse Charge).",
  reverse_charge_info_2:
    "Bei der Umkehrung der Steuerschuldnerschaft ist die Mehrwertsteuer zu entrichten.",
  employees_premium_banner_title: "Das Limit wurde erreicht",
  employees_premium_banner_description:
    "Wechseln Sie zu einem höheren Tarif, um neue Kunden ohne Einschränkungen hinzufügen zu können.",
  users_premium_banner_title: "Das Limit wurde erreicht",
  users_premium_banner_description:
    "Wechseln Sie zu einem höheren Tarif, um neue Kunden ohne Einschränkungen hinzufügen zu können.",
  google_calendar: "Google Kalender",
  google_meet: "Google Meet",
  google_meet_description:
    "Legen Sie den Standort Ihrer Dienstleistung auf Google Meet in Calendesk fest, damit das Calendesk-System bei jeder Buchung einen individuellen Link zu dem Termin erstellt. Der Link wird an Sie und Ihren Kunden weitergeleitet, sobald die Buchung erstellt wurde.",
  google_calendar_connect_info:
    "Klicken Sie unten, um Ihren Google-Kalender mit dem Calendesk-Kalender zu verknüpfen. Sobald die Verknüpfung hergestellt ist, werden alle Ihre Termine und deren Änderungen an Ihren Google-Kalender gesendet. Denken Sie daran, dass der Kalender nur in eine Richtung funktioniert. Wenn Sie einen Besuch im Google-Kalender ändern, wird diese Änderung nicht in Calendesk angezeigt. Nehmen Sie alle Änderungen immer über Calendesk vor.",
  google_calendar_disconnect_info:
    "Die Synchronisierung mit Google Calendar ist aktiv. Klicken Sie unten, um die Verbindung zu trennen.",
  disconnect_button_title: "Trennen",
  plugin_only_for_employees_info:
    "Das Modul funktioniert nur mit einem Mitarbeiterkonto. Sie müssen ein Mitarbeiterkonto anlegen, um Buchungen annehmen zu können.",
  plugin_not_active_employee_info:
    "Das Modul ist nicht aktiv. Wenden Sie sich an den Administrator, um das Modul zu aktivieren.",
  google_calendar_auth_success_title: "Korrekte Google-Autorisierung",
  google_calendar_disconnect_dialog_title: "Google Kalender trennen",
  google_calendar_disconnect_dialog_info:
    "Sind Sie sicher, dass Sie die Verbindung zu Google Calendar trennen möchten? Ihre zukünftigen Calendesk-Besuche und -Sperren werden nicht mehr an Google Calendar gesendet.",
  google_calendar_disconnect_success_message:
    "Die Verbindung zum Kalender wurde korrekt getrennt.",
  buy: "Kaufen",
  on_time_payment: "Einmalige Zahlung",
  plan_expired_title: "Ihr aktueller Plan ist abgelaufen",
  building_plans_title:
    "Wir aktivieren alle Funktionen, die Sie benötigen &#128640;&#128640;!",
  building_plans_subtitle:
    "Es wird nicht länger als ein paar Sekunden dauern ...",
  go_to_summary: "Zur Zusammenfassung gehen",
  cd_base_standard: "Calendesk Standard",
  cd_base_pro: "Calendesk Pro",
  cd_base_enterprise: "Calendesk Enterprise",
  plans_description_standard:
    "Nutzen Sie die Möglichkeiten von Calendesk, um Ihr Geschäft zu verbessern.",
  plans_description_pro:
    "Für die Anspruchsvollsten, die auf Wachstum ausgerichtet sind.",
  plans_description_enterprise:
    "Volle Flexibilität, kontaktieren Sie uns für weitere Informationen.",
  enterprise_price_list_info: "Einzelvertrag mit jährlicher Abrechnung",
  plans_billed_annually: "jährlich abgerechnet",
  plans_or: "oder",
  plans_month_to_month_payment: "monatlich abgerechnet",
  plans_featured: "Die meistgewählten",
  plans_feature_name_1: "Ihre Webadresse",
  plans_feature_lite_1: "Primäre Subdomäne: calendesk.net",
  plans_feature_lite_1_tooltip:
    "Wenn Sie keine Möglichkeit haben, Ihre eigene Domäne festzulegen, sieht die Webadresse Ihrer Website wie die folgende aus: www.firmenname.calendesk.net.",
  plans_feature_standard_1: "Eigene Domäne",
  plans_feature_standard_1_tooltip:
    "Möglichkeit, eine beliebige Webadresse einzurichten, nachdem eine Domäne beim Hostingdienst erworben wurde.",
  plans_feature_pro_1: "Eigene Domäne",
  plans_feature_pro_1_tooltip:
    "Möglichkeit, eine beliebige Webadresse einzurichten, nachdem eine Domäne beim Hostingdienst erworben wurde.",
  plans_feature_name_2: "Anzahl der Webseiten",
  plans_feature_standard_2: "Maximal 1 Webseite",
  plans_feature_standard_2_tooltip:
    "Möglichkeit, 1 Website mit einer beliebigen Anzahl von Unterseiten für Ihr Unternehmen zu erstellen.",
  plans_feature_pro_2: "Maximal 3 Webseiten",
  plans_feature_pro_2_tooltip:
    "Möglichkeit, 3 dedizierte Websites zu erstellen, mit einer unbegrenzten Anzahl von Unterseiten für die verschiedenen Dienstleistungen, die Sie anbieten (z. B. unter SEO und SEM).",
  plans_feature_enterprise_2: "Unbegrenzt",
  plans_feature_enterprise_2_tooltip:
    "Die Möglichkeit, eine unbegrenzte Anzahl von Seiten für verschiedene Dienstleistungen und verschiedene Kunden zu erstellen - z. B. Seiten für einzelne Kunden, für Unternehmen, die ein bestimmtes Produkt oder eine Dienstleistung bewerben, Seiten für Facebook und spezielle Seiten für Suchmaschinen (SEO und SEM).",
  plans_feature_name_3: "Website-Editor",
  plans_feature_standard_3: "Ja",
  plans_feature_standard_3_tooltip:
    "Die Möglichkeit, eine unbegrenzte Anzahl von Abschnitten auf der Seite zu erstellen, Zugang zu einer breiten Palette von Grafiken und die Möglichkeit, die Seite zu personalisieren.",
  plans_feature_pro_3: "Ja",
  plans_feature_pro_3_tooltip:
    "Die Möglichkeit, eine unbegrenzte Anzahl von Abschnitten auf der Seite zu erstellen, Zugang zu einer breiten Palette von Grafiken und die Möglichkeit, die Seite zu personalisieren.",
  plans_feature_enterprise_3: "Ja",
  plans_feature_enterprise_3_tooltip:
    "Die Möglichkeit, eine unbegrenzte Anzahl von Abschnitten auf der Seite zu erstellen, Zugang zu einer breiten Palette von Grafiken und die Möglichkeit, die Seite zu personalisieren.",
  plans_feature_name_4: "Mobile App für Kunden",
  plans_feature_standard_4: "Ja",
  plans_feature_standard_4_tooltip:
    "Die Möglichkeit, eine einfache mobile App für Ihre Kunden zu erstellen, die es den Kunden so einfach wie möglich macht, Sie zu erreichen und Ihre Dienstleistungen zu buchen.",
  plans_feature_pro_4: "Ja",
  plans_feature_pro_4_tooltip:
    "Es besteht die Möglichkeit, eine einfache mobile App oder eine fortgeschrittene personalisierte Version zu erstellen - ganz auf Sie und Ihre Erwartungen zugeschnitten.",
  plans_feature_enterprise_4: "Ja",
  plans_feature_enterprise_4_tooltip:
    "Es besteht die Möglichkeit, eine einfache mobile App oder eine fortgeschrittene personalisierte Version zu erstellen - ganz auf Sie und Ihre Erwartungen zugeschnitten.",
  plans_feature_name_5: "E-Mail Benachrichtigungen",
  plans_feature_standard_5: "500 Stück pro Monat",
  plans_feature_standard_5_tooltip:
    "Senden Sie Ihren Kunden Buchungs-E-Mails (Bestätigung der Terminannahme, -änderung oder -stornierung), Transaktions-E-Mails, Benachrichtigungen und Sonderangebote.",
  plans_feature_pro_5: "1.000 Stück pro Monat",
  plans_feature_pro_5_tooltip:
    "Senden Sie Ihren Kunden Buchungs-E-Mails (Bestätigung der Terminannahme, -änderung oder -stornierung), Transaktions-E-Mails, Benachrichtigungen und Sonderangebote.",
  plans_feature_enterprise_5: "Unbegrenzt",
  plans_feature_enterprise_5_tooltip:
    "Senden Sie Ihren Kunden Buchungs-E-Mails (Bestätigung der Terminannahme, -änderung oder -stornierung), Transaktions-E-Mails, Benachrichtigungen und Sonderangebote.",
  plans_feature_name_6: "Das E-Mail-Paket vergrößern",
  plans_feature_standard_6: "Ja",
  plans_feature_standard_6_tooltip:
    "Jede beliebige Menge kann für 0,02 € pro E-Mail erworben werden.",
  plans_feature_pro_6: "Ja",
  plans_feature_pro_6_tooltip:
    "Jede beliebige Menge kann für 0,01 € pro E-Mail erworben werden.",
  plans_feature_enterprise_6: "Ja",
  plans_feature_enterprise_6_tooltip:
    "Jede beliebige Anzahl kann zu einem individuell festgelegten Preis erworben werden.",
  plans_feature_name_7: "SMS-Benachrichtigungen",
  plans_feature_standard_7: "50 Stück pro Monat",
  plans_feature_standard_7_tooltip:
    "Senden Sie Ihren Kunden SMS-Nachrichten - bestätigen Sie eine Buchung, eine Terminänderung oder eine Stornierung, informieren Sie sie über Werbeaktionen, Sonderangebote und Rabatte.",
  plans_feature_pro_7: "100 Stück pro Monat",
  plans_feature_pro_7_tooltip:
    "Senden Sie Ihren Kunden SMS-Nachrichten - bestätigen Sie eine Buchung, eine Terminänderung oder eine Stornierung, informieren Sie sie über Werbeaktionen, Sonderangebote und Rabatte.",
  plans_feature_enterprise_7: "Unbegrenzt",
  plans_feature_enterprise_7_tooltip:
    "Senden Sie Ihren Kunden SMS-Nachrichten - bestätigen Sie eine Buchung, eine Terminänderung oder eine Stornierung, informieren Sie sie über Werbeaktionen, Sonderangebote und Rabatte.",
  plans_feature_name_8: "Das SMS-Paket vergrößern",
  plans_feature_standard_8: "Ja",
  plans_feature_standard_8_tooltip:
    "Sie können so viele SMS kaufen, wie Sie möchten, für 0,07 € pro SMS + ein spezielles Paket mit 200 SMS pro Monat für 7,99 €.",
  plans_feature_pro_8: "Ja",
  plans_feature_pro_8_tooltip:
    "Sie können beliebig viele SMS für 0,06 € pro SMS kaufen + PREMIUM-Paket mit 250 Stück pro Monat für 7,99 €.",
  plans_feature_enterprise_8: "Ja",
  plans_feature_enterprise_8_tooltip:
    "Sie können eine gewünschte Anzahl zu einem individuell festgelegten Preis kaufen.",
  plans_feature_name_10: "Online-Zahlungen und Abonnements",
  plans_feature_standard_10: "Ja",
  plans_feature_standard_10_tooltip:
    "Bezahlen Sie online - schnell, bequem und sicher. Machen Sie sich keine Gedanken über Kleingeld und leere Buchungen, an denen Sie nichts verdienen.",
  plans_feature_pro_10: "Ja",
  plans_feature_pro_10_tooltip:
    "Bezahlen Sie online - schnell, bequem und sicher. Machen Sie sich keine Gedanken über Kleingeld und leere Buchungen, an denen Sie nichts verdienen.",
  plans_feature_enterprise_10: "Ja",
  plans_feature_enterprise_10_tooltip:
    "Bezahlen Sie online - schnell, bequem und sicher. Machen Sie sich keine Gedanken über Kleingeld und leere Buchungen, an denen Sie nichts verdienen.",
  plans_feature_name_11: "Buchungssystem",
  plans_feature_standard_11: "Ja",
  plans_feature_standard_11_tooltip:
    "Volle Online-Buchungsmöglichkeit mit der Möglichkeit für den Kunden, Anmerkungen zu machen und einen Kommentar zu hinterlassen.",
  plans_feature_pro_11: "Ja",
  plans_feature_pro_11_tooltip:
    "Volle Online-Buchungsmöglichkeit mit der Möglichkeit für den Kunden, Anmerkungen zu machen und einen Kommentar zu hinterlassen.",
  plans_feature_enterprise_11: "Ja",
  plans_feature_enterprise_11_tooltip:
    "Volle Online-Buchungsmöglichkeit mit der Möglichkeit für den Kunden, Anmerkungen zu machen und einen Kommentar zu hinterlassen.",
  plans_feature_name_12: "Google Kalender Integration",
  plans_feature_standard_12: "Ja",
  plans_feature_standard_12_tooltip:
    "Kontrollieren Sie Termine in Echtzeit, exportieren Sie alle Buchungen, Aufträge und Termine in den Google Kalender. Machen Sie die ausgewählten Ereignisse für Ihre Mitarbeiter und Kunden sichtbar. Erleichtern Sie ihnen das Leben und steigern Sie die Effizienz Ihres Unternehmens.",
  plans_feature_pro_12: "Ja",
  plans_feature_pro_12_tooltip:
    "Kontrollieren Sie Termine in Echtzeit, exportieren Sie alle Buchungen, Aufträge und Termine in den Google Kalender. Machen Sie die ausgewählten Ereignisse für Ihre Mitarbeiter und Kunden sichtbar. Erleichtern Sie ihnen das Leben und steigern Sie die Effizienz Ihres Unternehmens.",
  plans_feature_enterprise_12: "Ja",
  plans_feature_enterprise_12_tooltip:
    "Kontrollieren Sie Termine in Echtzeit, exportieren Sie alle Buchungen, Aufträge und Termine in den Google Kalender. Machen Sie die ausgewählten Ereignisse für Ihre Mitarbeiter und Kunden sichtbar. Erleichtern Sie ihnen das Leben und steigern Sie die Effizienz Ihres Unternehmens.",
  plans_feature_name_14: "Newsletter",
  plans_feature_standard_14: "Ja",
  plans_feature_standard_14_tooltip:
    "Erstellen Sie einen einfachen Newsletter für den Kunden - erinnern Sie ihn an Ihre Dienstleistungen, bieten Sie Rabatte, Werbeaktionen und zusätzliche Dienstleistungen an.",
  plans_feature_pro_14: "Ja",
  plans_feature_pro_14_tooltip:
    "Erstellen Sie einen einfachen Newsletter für den Kunden - erinnern Sie ihn an Ihre Dienstleistungen, bieten Sie Rabatte, Werbeaktionen und zusätzliche Dienstleistungen an.",
  plans_feature_enterprise_14: "Ja",
  plans_feature_enterprise_14_tooltip:
    "Erstellen Sie einen einfachen Newsletter für den Kunden - erinnern Sie ihn an Ihre Dienstleistungen, bieten Sie Rabatte, Werbeaktionen und zusätzliche Dienstleistungen an.",
  plans_feature_name_18: "Anzahl der Buchungen",
  plans_feature_standard_18: "750 Buchungen pro Monat",
  plans_feature_standard_18_tooltip:
    "Bearbeitung von bis zu 750 Buchungen pro Monat.",
  plans_feature_pro_18: "2.500 Buchungen pro Monat",
  plans_feature_pro_18_tooltip:
    "Bearbeitung von maximal 2.500 Buchungen pro Monat.",
  plans_feature_enterprise_18: "Unbegrenzt",
  plans_feature_enterprise_18_tooltip:
    "Kein Limit für die Anzahl der Buchungen.",
  plans_feature_name_19: "Anzahl der Mitarbeiterkonten",
  plans_feature_standard_19: "5 Mitarbeiterkonten",
  plans_feature_standard_19_tooltip:
    "Begrenzt zu 5 App-Benutzern - Möglichkeit, Rollen und Zugriffsrechte auf einfache Weise festzulegen.",
  plans_feature_pro_19: "15 Mitarbeiterkonten",
  plans_feature_pro_19_tooltip:
    "Begrenzt zu 15 App-Benutzern - Möglichkeit, Rollen und Zugriffsrechte auf einfache Weise festzulegen.",
  plans_feature_enterprise_19: "Unbegrenzt",
  plans_feature_enterprise_19_tooltip:
    "Keine Begrenzung der Anzahl der unterstützten Konten - unbegrenzte Rollen und mehrere Zugriffsrechte. Die Anzahl der unterstützten Konten wird individuell festgelegt.",
  plans_feature_name_20: "Datenschutzpolitik und Allgemeine Bedingungen",
  plans_feature_standard_20: "Ja",
  plans_feature_standard_20_tooltip:
    "Erstellen Sie Dokumente mit der gemeinsam mit unseren Anwälten erstellten App.",
  plans_feature_pro_20: "Ja",
  plans_feature_pro_20_tooltip:
    "Erstellen Sie Dokumente mit der gemeinsam mit unseren Anwälten erstellten App.",
  plans_feature_enterprise_20: "Ja",
  plans_feature_enterprise_20_tooltip:
    "Erstellen Sie Dokumente mit der gemeinsam mit unseren Anwälten erstellten App.",
  plans_feature_name_21: "Google Meet Integration",
  plans_feature_standard_21: "Ja",
  plans_feature_standard_21_tooltip:
    "Erstellen Sie einen Google Meet-Link, sobald Sie buchen.",
  plans_feature_pro_21: "Ja",
  plans_feature_pro_21_tooltip:
    "Erstellen Sie einen Google Meet-Link, sobald Sie buchen.",
  plans_feature_enterprise_21: "Ja",
  plans_feature_enterprise_21_tooltip:
    "Erstellen Sie einen Google Meet-Link, sobald Sie buchen.",
  plans_feature_name_22: "Integration des Rechnungsshops",
  plans_feature_standard_22: "Ja",
  plans_feature_standard_22_tooltip:
    "Stellen Sie Ihrem Kunden sofort nach der Zahlung eine MwSt.-Rechnung aus.",
  plans_feature_pro_22: "Ja",
  plans_feature_pro_22_tooltip:
    "Stellen Sie Ihrem Kunden sofort nach der Zahlung eine MwSt.-Rechnung aus.",
  plans_feature_enterprise_22: "Ja",
  plans_feature_enterprise_22_tooltip:
    "Stellen Sie Ihrem Kunden sofort nach der Zahlung eine MwSt.-Rechnung aus.",
  plans_feature_name_23: "Zoom Integration",
  plans_feature_standard_23: "Ja",
  plans_feature_standard_23_tooltip:
    "Erstellen Sie einen Link zum Zoom-Meeting gleich nach der Buchung.",
  plans_feature_pro_23: "Ja",
  plans_feature_pro_23_tooltip:
    "Erstellen Sie einen Link zum Zoom-Meeting gleich nach der Buchung.",
  plans_feature_enterprise_23: "Ja",
  plans_feature_enterprise_23_tooltip:
    "Erstellen Sie einen Link zum Zoom-Meeting gleich nach der Buchung.",
  plans_feature_name_24: "Zapier Integration",
  plans_feature_standard_24: "Kein",
  plans_feature_standard_24_tooltip:
    "Diese Option ist ab dem Pro-Tarif verfügbar.",
  plans_feature_pro_24: "Ja",
  plans_feature_pro_24_tooltip:
    "Verbinden Sie Ihre Apps und automatisieren Sie Ihre Arbeitsabläufe.",
  plans_feature_enterprise_24: "Ja",
  plans_feature_enterprise_24_tooltip:
    "Verbinden Sie Ihre Apps und automatisieren Sie Ihre Arbeitsabläufe.",
  plans_feature_name_25: "API-Zugriff",
  plans_feature_standard_25: "Kein",
  plans_feature_standard_25_tooltip:
    "Diese Option ist ab dem Pro-Tarif verfügbar.",
  plans_feature_pro_25: "Ja",
  plans_feature_pro_25_tooltip:
    "Verwenden Sie die in Calendesk gespeicherten Daten in allen Apps, mit denen Sie arbeiten.",
  plans_feature_enterprise_25: "Ja",
  plans_feature_enterprise_25_tooltip:
    "Verwenden Sie die in Calendesk gespeicherten Daten in allen Apps, mit denen Sie arbeiten.",
  plans_feature_name_26: "Kalender-Widget",
  plans_feature_standard_26: "Ja",
  plans_feature_standard_26_tooltip:
    "Integration des Buchungssystems in Ihre eigene Website möglich.",
  plans_feature_pro_26: "Ja",
  plans_feature_pro_26_tooltip:
    "Integration des Buchungssystems in Ihre eigene Website möglich.",
  plans_feature_enterprise_26: "Ja",
  plans_feature_enterprise_26_tooltip:
    "Integration des Buchungssystems in Ihre eigene Website möglich.",
  plans_feature_name_27: "Anzahl der einzelnen Seitenaufrufe pro Monat",
  plans_feature_standard_27: "5.000 Seitenaufrufe pro Monat",
  plans_feature_standard_27_tooltip:
    "5.000 einmalige Besuche pro Monat auf Ihrer Website oder Ihrem Kalender.",
  plans_feature_pro_27: "10.000 Seitenaufrufe pro Monat",
  plans_feature_pro_27_tooltip:
    "10 000 einmalige Besuche pro Monat auf Ihrer Website oder Ihrem Kalender.",
  plans_feature_enterprise_27: "Unbegrenzt",
  plans_feature_enterprise_27_tooltip:
    "Die Anzahl der einzelnen Besuche wird individuell festgelegt.",
  plans_feature_name_28: "Microsoft Outlook Integration",
  plans_feature_standard_28: "Ja",
  plans_feature_pro_28: "Ja",
  plans_feature_standard_28_tooltip:
    "Kontrollieren Sie Termine in Echtzeit, exportieren Sie alle Buchungen, Aufträge und Termine in den Outlook Kalender. Machen Sie die ausgewählten Ereignisse für Ihre Mitarbeiter und Kunden sichtbar. Erleichtern Sie ihnen das Leben und steigern Sie die Effizienz Ihres Unternehmens.",
  plans_feature_pro_28_tooltip:
    "Kontrollieren Sie Termine in Echtzeit, exportieren Sie alle Buchungen, Aufträge und Termine in den Outlook Kalender. Machen Sie die ausgewählten Ereignisse für Ihre Mitarbeiter und Kunden sichtbar. Erleichtern Sie ihnen das Leben und steigern Sie die Effizienz Ihres Unternehmens.",
  plans_feature_enterprise_28: "Ja",
  plans_feature_enterprise_28_tooltip:
    "Kontrollieren Sie Termine in Echtzeit, exportieren Sie alle Buchungen, Aufträge und Termine in den Outlook Kalender. Machen Sie die ausgewählten Ereignisse für Ihre Mitarbeiter und Kunden sichtbar. Erleichtern Sie ihnen das Leben und steigern Sie die Effizienz Ihres Unternehmens.",
  plans_feature_name_29: "Microsoft Teams Integration",
  plans_feature_standard_29: "Ja",
  plans_feature_standard_29_tooltip:
    "Erstellen Sie einen Link zum Teams-Meeting gleich nach der Buchung.",
  plans_feature_pro_29: "Ja",
  plans_feature_pro_29_tooltip:
    "Erstellen Sie einen Link zum Teams-Meeting gleich nach der Buchung.",
  plans_feature_enterprise_29: "Ja",
  plans_feature_enterprise_29_tooltip:
    "Erstellen Sie einen Link zum Teams-Meeting gleich nach der Buchung.",
  plans_feature_name_30: "Skype Integration",
  plans_feature_standard_30: "Ja",
  plans_feature_standard_30_tooltip:
    "Erstellen Sie einen Link zum Skype-Meeting gleich nach der Buchung.",
  plans_feature_pro_30: "Ja",
  plans_feature_pro_30_tooltip:
    "Erstellen Sie einen Link zum Skype-Meeting gleich nach der Buchung.",
  plans_feature_enterprise_30: "Ja",
  plans_feature_enterprise_30_tooltip:
    "Erstellen Sie einen Link zum Skype-Meeting gleich nach der Buchung.",
  plans_feature_name_31: "WhatsApp Integration",
  plans_feature_standard_31: "Ja",
  plans_feature_standard_31_tooltip:
    "Erstellen Sie einen Link zum WhatsApp-Meeting gleich nach der Buchung.",
  plans_feature_pro_31: "Ja",
  plans_feature_pro_31_tooltip:
    "Erstellen Sie einen Link zum WhatsApp-Meeting gleich nach der Buchung.",
  plans_feature_enterprise_31: "Ja",
  plans_feature_enterprise_31_tooltip:
    "Erstellen Sie einen Link zum WhatsApp-Meeting gleich nach der Buchung.",
  plans_trial_faq_2_title: "Kann ich mich jederzeit von Calendesk abmelden?",
  plans_trial_faq_2_description_1:
    "Wenn Sie Ihr Abonnement kündigen möchten, werden wir das sehr bedauern, aber natürlich <b>können Sie das tun, wann immer Sie wollen</b>. Calendesk bindet Sie nicht an langfristige Verträge. Sie können Calendesk mit monatlicher oder jährlicher Zahlung nutzen (bei jährlicher Zahlung erhalten Sie einen Rabatt von 20 %).",
  plans_trial_faq_3_title: "Kann ich meinen Plan jederzeit ändern?",
  plans_trial_faq_3_description_1:
    "Ja, natürlich.<b>Sie können jederzeit zwischen den Tarifen wechseln.</b> Unser Zahlungsanbieter wird die Beträge neu berechnen, so dass Sie bei einem solchen Wechsel nichts verlieren. Wenn Sie den Plan während des Abrechnungszeitraums erhöhen, wird Ihnen nur der verbleibende Zeitraum berechnet. Wenn Sie den Plan während des Abrechnungszeitraums herabsetzen, verwenden wir den verbleibenden Betrag für künftige Zahlungen oder erstatten ihn Ihnen zurück.",
  plans_trial_faq_4_title:
    "Was geschieht nach Ablauf der Vertragslaufzeit? Verlängert sich der Antrag automatisch und wird eine zusätzliche Gebühr für den gesamten Zeitraum erhoben?",
  plans_trial_faq_4_description_1:
    'Ja, der gewählte Tarif verlängert sich automatisch. Wenn Sie nicht möchten, dass sich der Plan im neuen Zeitraum verlängert, können Sie ihn jederzeit auf der Registerkarte "Konten und Zahlungen" kündigen.',
  plans_trial_faq_5_title:
    "Verliere ich mein Konto und meine Daten, wenn ich vergesse, meinen Calendesk-Nutzungszeitraum zu verlängern?",
  plans_trial_faq_5_description_1:
    "Ihre Daten sind noch eine Zeit lang in unserem System verfügbar, so dass Sie sicher wieder auf Ihr Konto und das von Ihnen erstellte Projekt zugreifen können.",
  plans_trial_faq_5_description_2:
    "Wenn die Vertragslaufzeit abgelaufen ist und Ihr Konto auch nur für ein paar Tage gesperrt wurde, haben Sie immer noch die Möglichkeit, es zurückzubekommen, sofern Sie die Gebühr bezahlen.",
  plans_trial_faq_5_description_3:
    "Sollte dies für Sie schwierig oder unklar sein, wenden Sie sich bitte an unser Support-Team.",
  plans_trial_faq_6_title:
    "Wie erkenne ich, wann meine Nutzungsdauer zu Ende geht?",
  plans_trial_faq_6_description_1:
    "Sobald Sie sich bei Ihrem Calendesk-App-Konto angemeldet haben, werden in der rechten Ecke der Name Ihres Tarifplans und die verbleibende Zeit angezeigt.",
  plans_trial_faq_6_description_2:
    "Außerdem erhalten Sie kurz vor Ablauf Ihres Tarifs eine Benachrichtigung in Form eines Pop-up-Fensters. Außerdem wird Ihnen eine E-Mail zugesandt.",
  plan_selected_title:
    "Herzlichen Glückwunsch! Sie haben den Tarifplan @{plan} ausgewählt.",
  plan_selected_subtitle_1:
    "Schneiden Sie den Plan auf die Bedürfnisse Ihres Unternehmens zu, und wählen Sie zusätzliche Pakete!",
  plan_selected_subtitle_2:
    "Sie können die Pakete jederzeit über das App-Menü ein- und ausschalten.",
  "plugins_sms-notifications_cd_base_lite_name": "Kontakt-Paket",
  "plugins_sms-notifications_cd_base_lite_description":
    "Senden Sie Ihren Kunden einfach und schnell SMS-Benachrichtigungen. Bestätigen Sie Buchungen, erinnern Sie an Termine und akzeptieren Sie Terminänderungen. Das Paket enthält 150 SMS-Nachrichten mit der Möglichkeit, regelmäßig neue Nachrichten zu erwerben.",
  "plugins_sms-notifications_cd_base_standard_name": "Kontakt Pro-Paket",
  "plugins_sms-notifications_cd_base_standard_description":
    "Senden Sie Ihren Kunden SMS-Benachrichtigungen in noch größerer Zahl. Erinnern Sie sie mit speziellen Nachrichten an Werbeaktionen, besondere Anlässe und Geburtstagsrabatte. Ermutigen Sie sie, sich zu melden und Ihre Website zu besuchen! Das Paket enthält 200 SMS-Nachrichten mit der Möglichkeit, regelmäßig neue Nachrichten zu kaufen.",
  "plugins_sms-notifications_cd_base_pro_name": "Kontakt Expert - Paket",
  "plugins_sms-notifications_cd_base_pro_description":
    "Senden Sie Ihren Kunden SMS-Benachrichtigungen in noch größerer Zahl. Erinnern Sie sie mit speziellen Nachrichten an Werbeaktionen, besondere Anlässe und Geburtstagsrabatte. Ermuntern Sie sie, Sie zu kontaktieren und Ihre Website zu besuchen! Das Paket enthält 250 SMS-Nachrichten mit der Möglichkeit, regelmäßig neue Nachrichten zu kaufen.",
  "plugins_mobile-app-staff_cd_base_lite_name": "Mobile App für Mitarbeiter",
  "plugins_mobile-app-staff_cd_base_lite_description":
    "Geben Sie Ihren Mitarbeitern die Möglichkeit, eine spezielle mobile App auf ihren Smartphones zu nutzen. Lassen Sie sie ihren Mitarbeiterkalender einsehen, die ihnen für den Tag zugewiesenen Aufgaben und die Kunden, die sie bedienen werden, überprüfen. Mit Push-Benachrichtigungen auf ihrem Telefon erhalten Ihre Mitarbeiter sofort alle Informationen, die sie von Ihnen benötigen.",
  plugins_booking_cd_base_lite_name: "Buchungs-Paket",
  plugins_booking_cd_base_lite_description:
    "Fügen Sie Ihrer App die Möglichkeit hinzu, ein voll funktionsfähiges Buchungssystem für Kunden zu erstellen. Bieten Sie ihnen eine Auswahl an spezifischen Serviceoptionen (z. B. Waschen, Schneiden, Kämmen) und Zugang zu den Terminen Ihres Personals - lassen Sie sie wählen, bei wem sie sich anmelden möchten.",
  "plugins_google-calendar_cd_base_standard_name": "Google Kalender",
  "plugins_google-calendar_cd_base_standard_description":
    "Kontrollieren Sie Termine in Echtzeit, exportieren Sie alle Buchungen, Aufträge und Termine in den Google Kalender. Machen Sie die ausgewählten Ereignisse für Ihre Mitarbeiter und Kunden sichtbar. Erleichtern Sie ihnen das Leben und steigern Sie die Effizienz Ihres Unternehmens. Erstellen Sie Erinnerungen, Aufgabenlisten und einen Tagesplaner - fügen Sie Facebook-Events hinzu und erinnern Sie sich an Ihre Kunden.",
  plugins_mobile_cd_base_lite_name: "Mobile - Paket",
  plugins_mobile_cd_base_lite_description:
    "Machen Sie Ihre Website vollständig kompatibel mit mobilen Geräten (Smartphones und Tablets). Mit diesem Paket können Sie problemlos volle Kompatibilität erreichen.",
  "plugins_mobile-app-customers-pro_cd_base_standard_name":
    "Mobile Pro - Paket",
  "plugins_mobile-app-customers-pro_cd_base_standard_description":
    "Erstellen Sie eine professionelle mobile App für Ihr Unternehmen. Lassen Sie Ihre Kunden eine App mit Ihrem Logo und selbstbestimmten Inhalten auf ihr Telefon herunterladen. Denken Sie über das Design nach, bestimmen Sie den Stil, die Farbe und die Art der Schriftarten. Wählen Sie die Funktionalitäten und den Umfang der angebotenen Dienstleistungen - entscheiden Sie selbst, von Anfang bis Ende.",
  "plugins_mobile-app-customers-pro_cd_base_pro_name": "Mobile Expert - Paket",
  "plugins_mobile-app-customers-pro_cd_base_pro_description":
    "Erstellen Sie eine professionelle mobile App für Ihr Unternehmen. Lassen Sie Ihre Kunden eine App mit Ihrem Logo und selbstbestimmten Inhalten auf ihr Telefon herunterladen. Denken Sie über das Design nach, bestimmen Sie den Stil, die Farbe und die Art der Schriftarten. Wählen Sie die Funktionalitäten und den Umfang der angebotenen Dienstleistungen - entscheiden Sie selbst, von Anfang bis Ende.",
  plugins_management_cd_base_lite_name: "Management - Paket",
  plugins_management_cd_base_lite_description:
    "Schalten Sie den unbegrenzten Zugang zur Marketing-Wissensdatenbank frei. Lernen Sie effektive, einfache und leicht umsetzbare Tipps kennen, die Ihnen helfen, Calendesk besser zu nutzen und Ihr Geschäft auszubauen. Hier finden Sie aktuelle Artikel, Kommentare und Studien und können sich regelmäßig über relevante Fakten informieren.",
  plugins_support_cd_base_standard_name: "Individuelle Expertenbetreuung",
  plugins_support_cd_base_standard_description:
    "Fügen Sie die Möglichkeit professioneller Hilfe zu Ihrer App hinzu. Ihr individueller technischer Berater hilft Ihnen bei der Lösung von Problemen, zeigt Ihnen die beste Vorgehensweise auf und macht Vorschläge, wie Sie Probleme in Zukunft vermeiden können.",
  "plugins_support-expert_cd_base_pro_name": "Individuelle Expertenbetreuung+",
  "plugins_support-expert_cd_base_pro_description":
    "Fügen Sie Ihrer App die Möglichkeit eines professionellen PREMIUM-Supports hinzu. Ihr individueller technischer Berater hilft Ihnen bei der Lösung jedes Problems auf einer Prioritätsbasis. Er oder sie steht Ihnen während des verlängerten Zeitraums zur Verfügung und wird jedes Problem zügig lösen.",
  "plugins_easy-going_cd_base_standard_name": "Easy Going - Paket",
  "plugins_easy-going_cd_base_standard_description":
    "Bedienen Sie eine unbegrenzte Anzahl von Kunden, schalten Sie alle betrieblichen Möglichkeiten von Calendesk frei und lassen Sie sich von der App beim Wachstum Ihres Unternehmens und der schrittweisen Steigerung Ihrer Gewinne unterstützen.\n",
  "plugins_alphanumeric-sms-sender_cd_base_standard_name":
    "Eigener SMS-Absendername",
  "plugins_alphanumeric-sms-sender_cd_base_standard_description":
    "Ihr eigener SMS-Absendername in Nachrichten an Kunden. Ersetzen Sie eine langweilige und zufällige Telefonnummer durch Ihren eigenen Namen und zeigen Sie sich von Ihrer besten Seite. Sie können Ihren eigenen Namen unter Profil -> Ihr Unternehmen festlegen.",
  "plugins_alphanumeric-sms-sender_cd_base_pro_name":
    "Eigener SMS-Absendername",
  "plugins_alphanumeric-sms-sender_cd_base_pro_description":
    "Ihr eigener SMS-Absendername in Nachrichten an Kunden. Ersetzen Sie eine langweilige und zufällige Telefonnummer durch Ihren eigenen Namen und zeigen Sie sich von Ihrer besten Seite. Sie können Ihren eigenen Namen unter Profil -> Ihr Unternehmen festlegen.",
  plans_thank_you_page_title:
    "Ihr Tarif und die ausgewählten Zusatzpakete wurden aktiviert!",
  plans_thank_you_page_title_part_1:
    "Ihr Plan und ausgewählte Zusatzpakete wurden",
  plans_thank_you_page_title_part_2: "aktiviert!",
  plans_thank_you_page_subtitle:
    'Sie können sie jederzeit auf der Registerkarte "Ihre Pakete" ein- oder ausschalten.',
  plans_thank_you_page_button: "Erste Schritte mit Calendesk",
  fill_invoice_data: "Die Rechnungsangaben vervollständigen.",
  cancel_plan_confirm_title: "Sind Sie sicher, dass Sie abbrechen wollen?",
  cancel_plan_confirm_text:
    "Gibt es ein Problem? Bevor Sie abbrechen, sprechen Sie mit uns und geben Sie uns eine Chance, uns zu verbessern. Sie finden den Chat in der unteren rechten Ecke, klicken Sie einfach darauf.",
  cancel_plan_confirm_button_1: "Okay, ich will bleiben!",
  cancel_plan_confirm_button_2: "Plan stornieren :(",
  purchase_remove: "Löschen",
  wb_align_center: "Zentrieren",
  wb_container_fluid: "Volle Breite des Abschnitts",
  wb_image_to_right: "Bild nach rechts",
  wb_image_width: "Bildbreite in Pixel, z. B. 200",
  wb_image_height: "Bildhöhe in Pixel, z. B. 200",
  wb_button_width: "Breite der Schaltfläche in Pixel z. B. 200",
  wb_image_contain: "Das ganze Bild anpassen",
  wb_button_elevation: "Schatten (Stufe) z. B. 3",
  wb_height: "Höhe des Abschnitts",
  wb_image_hide_on_mobile: "Bild auf Smartphones ausblenden",
  wb_buttons_position_title: "Position der Schaltfläche",
  wb_form_position_title: "Formular Position",
  wb_image_position_title: "Position der Bilder",
  wb_position_top_right_title: "Oben rechts",
  wb_position_top_left_title: "Oben links",
  wb_position_top_center_title: "Oben Mitte",
  wb_position_bottom_right_title: "Unten rechts",
  wb_position_bottom_left_title: "Unten links",
  wb_position_bottom_center_title: "Unten Mitte",
  wb_create_new_website: "Neu starten",
  wb_image_hide: "Bild ausblenden",
  stripe_is_not_available_in_your_plan:
    "Stripe-Zahlungen sind im Lite-Tarif nicht verfügbar. Upgrade auf einen Standard- oder Pro-Tarif.",
  fakturownia_is_not_available_in_your_plan:
    "Rechnungsshop ist im Lite-Tarif nicht verfügbar. Upgrade auf einen Standard- oder Pro-Tarif.",
  help: "Hilfe",
  upselling: "Upselling",
  simple_store_product_list: "Produkte",
  simple_store_product_transaction_list: "Transaktionen",
  simple_store_product_list_product_name: "Name",
  simple_store_product_list_product_price: "Preis",
  no_simple_store_product_info:
    "Sie haben noch keine Produkte hinzugefügt, klicken Sie unten, um Ihr erstes Produkt hinzuzufügen.",
  no_subscriptions_info:
    "Sie haben noch keine Abonnements hinzugefügt. Klicken Sie unten, um Ihr erstes Abonnement hinzuzufügen.",
  simple_store_product_add_title: "Produkt hinzufügen",
  subscriptions_add_title: "Abonnement hinzufügen",
  subscriptions_update_title: "Ihr Abonnement aktualisieren",
  no_simple_store_product_transaction_info:
    "Sie haben noch keinen Verkauf von Produkten registriert. Wenn Produkte bereits hinzugefügt wurden, können Sie unten den Verkauf eines beliebigen davon einem ausgewählten Kunden zuweisen.",
  simple_store_product_update_title: "Produkte bearbeiten",
  wb_button_edit_menu_simple_store_product: "Produkt auswählen",
  wb_button_edit_menu_booking: "Dienstleistung auswählen",
  wb_cta_button_type_1: "Weiterleitung",
  wb_cta_button_type_2: "Buchung",
  wb_cta_button_type_3: "Produkt",
  wb_cta_button_type_4: "Abonnement",
  simple_store_product: "Produkt",
  booking_payments: "Buchungen",
  payment_type_select_box: "Zahlungsmethode auswählen",
  wb_round_zero_title: "Kein",
  wb_round_sm_title: "Klein",
  wb_round_md_title: "Mittel",
  wb_round_lg_title: "Groß",
  wb_round_xl_title: "XL",
  wb_round_pill_title: "Pille",
  wb_round_circle_title: "Kreis",
  wb_image_rounded: "Abrundung des Bildes",
  wb_gallery_image_number: "Anzahl der Fotos in der Galerie (Desktop)",
  wb_gallery_mobile_image_number:
    "Anzahl der Fotos in der Galerie (Smartphone)",
  wb_gallery_width: "Breite der Galerie in Prozent, z. B. 50",
  wb_gallery_height: "Höhe der Galerie in Pixel, z. B. 200",
  wb_gallery_distance: "Abstand zwischen den Bildern",
  wb_image_zoom_on_click: "Das Bild anklicken, um es zu vergrößern",
  wb_gallery_style: "Stil der Galerie",
  wb_gallery_style_1_title: "Stil 1",
  wb_gallery_style_2_title: "Stil 2",
  wb_gallery_style_3_title: "Stil 3",
  help_center_title:
    "Erfahren Sie in kurzen Videos mehr über die Calendesk-Funktionen.",
  help_center_group_1: "Einleitung",
  help_center_group_2: "Buchungssystem",
  help_center_group_3: "Kunden und Benachrichtigungen",
  help_center_group_4: "Website-Editor",
  help_center_group_5: "Mobile App",
  help_center_group_6: "Zahlungen",
  help_center_group_1_video_1_title:
    "Einführung in Calendesk - ein schneller Überblick über alle Funktionen",
  help_center_group_1_video_2_title:
    "Vervollständigen Sie Ihre Unternehmensdaten",
  help_center_group_2_video_1_title:
    "Mitarbeiter - wie füge ich eine neue Person hinzu, die Buchungen entgegennimmt?",
  help_center_group_2_video_2_title:
    "Dienstleistungen - was sind sie und wie füge ich eine neue Dienstleistung in das Buchungssystem ein?",
  help_center_group_2_video_3_title:
    "Dienstleistungen - wie verwaltet man die Liste der Dienstleistungen?",
  help_center_group_2_video_4_title:
    "Kalender - wie kann ich meinen Buchungskalender verwalten?",
  help_center_group_2_video_5_title:
    "Kalender - Termine, die nicht mit dem Besuch des Kunden zusammenhängen",
  help_center_group_2_video_6_title:
    "Kalender - Einschränkungen in den Kalendern der Kunden",
  help_center_group_3_video_1_title:
    "Wie kann ich meine Kunden in Calendesk verwalten? Versand von Benachrichtigungen an Kunden und Newsletter.",
  help_center_group_3_video_2_title:
    "Personalisierung von E-Mail-, SMS- und Push-Benachrichtigungen",
  help_center_group_4_video_1_title:
    "Ihre eigene Website - eine Einführung in den Calendesk-Editor",
  help_center_group_4_video_2_title:
    "Eigene Website - Überblick über die grundlegenden Funktionalitäten",
  help_center_group_4_video_3_title: "Eigene Website - Kopf- und Fußzeile",
  help_center_group_4_video_4_title:
    "Eigene Website - Kopf- und Fußzeile ersetzen",
  help_center_group_4_video_5_title:
    "Eigene Website - wie fügt man Abschnitte hinzu?",
  help_center_group_4_video_6_title:
    "Eigene Website - wie richtet man eine Domain ein?",
  help_center_group_5_video_1_title:
    "Mobile App mit Buchungssystem - Beschreibung des Webeditors",
  help_center_group_5_video_2_title:
    "Mobile App mit Buchungssystem - Präsentation der App im App Store oder bei Google Play",
  help_center_group_6_video_1_title:
    "Wie kann ich Stripe-Zahlungen mit meinem Buchungssystem und meinem Shop verknüpfen?",
  help_center_group_6_video_2_title:
    "Wie aktiviert man den Przelewy24-Service in Stripe?",
  help_center_group_6_video_3_title:
    "Wie berechnen Sie Ihre Dienstleistungen online?",
  help_center_group_6_video_4_title:
    "Der simple Online-Shop in Calendesk - wie kann man Produkte online bezahlen?",
  help_center_group_1_video_1_description:
    "In diesem Video erfahren Sie, welche Möglichkeiten Calendesk bietet. Hier wird die Struktur der gesamten Plattform vorgestellt. In diesem Leitfaden erfahren Sie, wie Sie durch die einzelnen Module von Calendesk navigieren können.",
  help_center_group_1_video_2_description:
    "Ihre Unternehmensdaten können auf Ihrer Website und Ihrer mobilen App angezeigt werden. In diesem Abschnitt erfahren Sie, wo Sie Ihre Unternehmensdaten eingeben können, wo Sie die Öffnungszeiten Ihres Arbeitsplatzes festlegen können, wo Sie Links zu sozialen Medien hinzufügen können und wie Sie die Allgemeinen Geschäftsbedingungen und die Datenschutzrichtlinie erstellen können.",
  help_center_group_2_video_1_description:
    "In diesem Video erfahren Sie, wie Sie ein Buchungssystem einrichten können. Hier erfahren Sie mehr über das Hinzufügen von Mitarbeitern, das Erteilen von Berechtigungen und das Konfigurieren ihrer Konten in Calendesk.",
  help_center_group_2_video_2_description:
    "In diesem Teil der Anleitung erfahren Sie, wie Sie Dienstleistungen hinzufügen und konfigurieren können. Hier erfahren Sie, wie Sie Zeiträume hinzufügen, Preise zuweisen und die Dienstleistungen beschreiben können.",
  help_center_group_2_video_3_description:
    "Mit der Dienstleistungsliste können Sie Ihre Dienstleistungen einfach verwalten und neue Angebote hinzufügen.",
  help_center_group_2_video_4_description:
    "Hier erfahren Sie, wie Sie den Calendesk-Kalender verwenden können. In diesem Video erklären wir, wie man Buchungen über die Mitarbeiterauswahl filtert und wie man Buchungen verwaltet.",
  help_center_group_2_video_5_description:
    "Was soll man tun, wenn ein Mitarbeiter Urlaub hat? In diesem Video erfahren Sie, wie Sie die Checkbox: „Ereignis, das nicht mit dem Besuch des Kunden zusammenhängt“ verwenden.",
  help_center_group_2_video_6_description:
    "Mit Admin-Rechten haben Sie unbegrenzte Möglichkeiten, Ihren Kalender zu verwalten. Erfahren Sie mehr in diesem Video.",
  help_center_group_3_video_1_description:
    "In diesem Kapitel erfahren Sie, mit welchen Tools Sie gute Kundenbeziehungen pflegen können und wie Sie Kunden in der Calendesk-Plattform verwalten.",
  help_center_group_3_video_2_description:
    "Erfahren Sie, wie Sie Benachrichtigungen verwenden und wie Sie sie ändern können. Es ist ein ideales Mittel, um mit Kunden und Mitarbeitern zu kommunizieren!",
  help_center_group_4_video_1_description:
    "Erfahren Sie, wie Sie mit unserem Website-Editor eine vollständig personalisierte Website erstellen können, über die Ihre Kunden Termine bei Ihnen buchen können. ",
  help_center_group_4_video_2_description:
    "Sehen Sie, wie die Personalisierung Ihrer Website funktioniert. Der Aufbau Ihres Online-Images kann definitiv mehr Spaß machen, als Sie glauben!",
  help_center_group_4_video_3_description:
    "Erfahren Sie mehr über die wichtigsten Abschnitte, aus denen Sie Ihre Website aufbauen werden.",
  help_center_group_4_video_4_description:
    "Erfahren Sie, wie Sie die Kopf- und Fußzeile im Webeditor ersetzen können",
  help_center_group_4_video_5_description:
    "Abschnitte im Webseiten-Editor. Lernen Sie, wie man Abschnitte hinzufügt und wie man sie verwaltet.",
  help_center_group_4_video_6_description:
    "Dieses Video erklärt, wie Sie Ihre eigene Domäne hinzufügen können. Eine gute Domäne ist der Schlüssel zum Erfolg Ihres Unternehmens. Hier erfahren Sie, wie Sie Ihre Webadresse einrichten.",
  help_center_group_5_video_1_description:
    "Eine mobile App mit einem Buchungssystem ist etwas, um das Sie jeder beneiden wird. Erfahren Sie, wie Sie Ihre eigene App erstellen und im App Store und bei Google Play veröffentlichen können.",
  help_center_group_5_video_2_description:
    "So sieht Ihre mobile App im Shop aus. In diesem Video erfahren Sie, wie man das macht.",
  help_center_group_6_video_1_description:
    "Ein Buchungssystem mit Zahlungen ist etwas, was das moderne Unternehmen braucht. Hier erfahren Sie, wie Sie Ihr Stripe-Konto mit Ihrem Calendesk-Konto verknüpfen.",
  help_center_group_6_video_2_description:
    "Stripe-Zahlungen bieten mehrere zusätzliche Konfigurationsmöglichkeiten. Sehen Sie, wie Sie zusätzliche Zahlungen über den Betreiber Przelewy24 aktivieren können.",
  help_center_group_6_video_3_description:
    "Erfahren Sie, wie Sie Ihre Dienste in Calendesk berechnen können. Hier erfahren Sie mehr über vorausbezahlte Besuche, Zahlungsstatus und sehen, wo Sie nach Zahlungstransaktionen suchen können.",
  help_center_group_6_video_4_description:
    "Ein einfacher Online-Shop bei Calendesk? Es ist möglich! Sehen Sie, wie Sie Ihre Produkte, für die Sie Einmalzahlungen akzeptieren, mit Ihrer Website verbinden können.",
  related_articles: "Verwandte Artikel",
  article_1_title: "Calendesk-Plattform - Überblick",
  article_2_title: "Profil - Ihr Unternehmen",
  article_3_title: "Mitarbeiter - Mitarbeiter hinzufügen",
  article_4_title: "Dienstleistungen - Kategorie hinzufügen",
  article_5_title: "Dienstleistungen - eine Dienstleistung hinzufügen",
  article_6_title: "Dienstleistungen - Liste der Dienstleistungen",
  article_7_title: "Mitarbeiter - Kalender",
  article_8_title: "Kunden - Kundenliste",
  article_9_title: "Kunden - Kunde hinzufügen",
  article_10_title: "Kunden - Buchungen",
  article_11_title: "Kunden - Newsletter",
  article_12_title: "Kunden - Benachrichtigungen",
  article_13_title: "Einstellungen - Benachrichtigungen",
  article_14_title: "Extras - Website",
  article_15_title: "Website-Personalisierung",
  article_16_title: "Website - Domäne",
};
